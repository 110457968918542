import React, { useContext,useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import {
  ServiceOrderColumnsEnum,
  SortDirections,
  ThemeColorsEnum,
  WorkOrderColumnsEnum,
} from "../../constants/enums";
import { AuthContext, LoadingContext } from "../../context/contextProvider";

import DashboardIcon from "@mui/icons-material/Dashboard";
import SettingsIcon from "@mui/icons-material/Settings";
import Enumerable from "linq";
import _ from "lodash";
import { Navigate } from "react-router-dom";
import Card from "../../components/cards/Card";
import FieldInfo from "../../components/forms/FieldInfo";
import AppForm from "../../components/forms/Form";
import FormCheckboxField from "../../components/forms/FormCheckboxField";
import AppFormLabel from "../../components/forms/FormLabel";
import FormSelectField from "../../components/forms/FormSelectField";
import FormTextField from "../../components/forms/FormTextField";
import FormTimePickerField from "../../components/forms/FormTimePickerField";
import SubmitButton from "../../components/forms/SubmitButton";
import { AppRoutes } from "../../constants/appRoutes";
import { LocalStorageKeys } from "../../constants/storageKeys";
import useCurrentUserInfo from "../../hooks/useCurrentUserInfo";
import Services from "../../services/services";
import Page from "../Page";

const title = "Branch Settings";
const breadcrumbs = [
  {
    IconComponent: DashboardIcon,
    title: "Dashhboard",
    to: AppRoutes.dashboard,
  },
  {
    IconComponent: SettingsIcon,
    title: title,
  },
];

const sortDirectionItems = Enumerable.from(SortDirections)
  .select((x) => ({ text: _.startCase(x.key), value: x.value }))
  .toArray();

const serviceOrderColumns = Enumerable.from(ServiceOrderColumnsEnum)
  .where((x) => x.value > 0)
  .select((x) => ({ text: _.startCase(x.key), value: x.value }))
  .toArray();
const workOrderColumns = Enumerable.from(WorkOrderColumnsEnum)
  .where((x) => x.value > 0)
  .select((x) => ({ text: _.startCase(x.key), value: x.value }))
  .toArray();

const SettingsPage = (props) => {
  const [timezones, setTimezones] = useState([]);
  const [liveBranchSettings, setLiveBranchSettings] = useState();

  const { branchModules, setBranchSettings, currentClient } =
    useContext(AuthContext);
  const { busy, setLoading } = useContext(LoadingContext);

  const userInfo = useCurrentUserInfo();

  useEffect(() => {
    initAsync();
    return () => {};
  }, [currentClient]); // eslint-disable-line react-hooks/exhaustive-deps

  const getBranchSettingsAsync = async () => {
    const response = await Services.api.settings.getBranchSettings();

    if (!response.ok)
      return Services.utility.toast(
        "Could not fetch the latest settings",
        ThemeColorsEnum.error
      );

    setLiveBranchSettings(response.data);
    setBranchSettings(response.data?.settings);
  };

  const getTimezonesAsync = async () => {
    const cachedTimezones = Services.localStorage.getProperty(
      LocalStorageKeys.timezones
    );

    if (cachedTimezones?.length > 0) return setTimezones(cachedTimezones);

    const response = await Services.api.settings.getTimezones();

    if (!response.ok)
      return Services.utility.toast(
        "Could not get the time zones",
        ThemeColorsEnum.error
      );

    Services.localStorage.setProperty(
      LocalStorageKeys.timezones,
      response.data
    );
    setTimezones(response.data);
  };

  const handleSubmit = async (values) => {
    setLoading(true);
    const response = await Services.api.settings.saveBranchSettings({
      ...liveBranchSettings,
      settings: values,
    });
    setLoading(false);

    if (!response.ok)
      return Services.utility.toast(
        "Could not save the settings",
        ThemeColorsEnum.error,
        response
      );

    setBranchSettings(response.data?.settings);
    Services.utility.toast("Settings saved!", ThemeColorsEnum.success);
  };

  const initAsync = async () => {
    setLoading(true);
    await getTimezonesAsync();
    await getBranchSettingsAsync();
    setLoading(false);
  };

  if (!userInfo?.isAdmin)
    return (
      <Navigate
        to={{
          pathname: AppRoutes.forbidden,
        }}
      />
    );

  return (
    <Page breadcrumbs={breadcrumbs} title={title}>
      <AppForm
        initialValues={{
          alwaysConvertToLiters:
            liveBranchSettings?.settings?.alwaysConvertToLiters ?? false,
          autoAssignOrders:
            liveBranchSettings?.settings?.autoAssignOrders ?? false,
          automaticallyLockDrivers:
            liveBranchSettings?.settings?.automaticallyLockDrivers ?? false,
          directDropDefaultQuantity:
            liveBranchSettings?.settings?.directDropDefaultQuantity ?? "50000",
          enableGpsTracking:
            liveBranchSettings?.settings?.enableGpsTracking ?? false,
          hideCompletedOrderDelay:
            liveBranchSettings?.settings?.hideCompletedOrderDelay ?? "0",
          serviceOrdersSortColumnIndex:
            liveBranchSettings?.settings?.serviceOrdersSortColumnIndex ??
            ServiceOrderColumnsEnum.dueDate,
          serviceOrdersSortDirection:
            liveBranchSettings?.settings?.serviceOrdersSortDirection ??
            SortDirections.ascending,
          timeZoneId: liveBranchSettings?.settings?.timeZoneId,
          workOrdersSortColumnIndex:
            liveBranchSettings?.settings?.workOrdersSortColumnIndex ??
            WorkOrderColumnsEnum.dueDate,
          workOrdersSortDirection:
            liveBranchSettings?.settings?.workOrdersSortDirection ??
            SortDirections.ascending,          
          // columns visibility
          columnsVisibility: {
            showCustomer:
              liveBranchSettings?.settings?.columnsVisibility?.showCustomer ??
              true,
            showNotes:
              liveBranchSettings?.settings?.columnsVisibility?.showNotes ??
              true,
            showProducts:
              liveBranchSettings?.settings?.columnsVisibility?.showProducts ??
              true,
            showCreatedDate:
              liveBranchSettings?.settings?.columnsVisibility
                ?.showCreatedDate ?? true,
            showAssignedTo:
              liveBranchSettings?.settings?.columnsVisibility?.showAssignedTo ??
              true,
            showOrderStatus:
              liveBranchSettings?.settings?.columnsVisibility
                ?.showOrderStatus ?? true,
            showDueDate:
              liveBranchSettings?.settings?.columnsVisibility?.showDueDate ??
              true,
            showCompletedDate:
              liveBranchSettings?.settings?.columnsVisibility
                ?.showCompletedDate ?? true,
            showCompletedBy:
              liveBranchSettings?.settings?.columnsVisibility
                ?.showCompletedBy ?? true,
            showConfirmation:
              liveBranchSettings?.settings?.columnsVisibility
                ?.showConfirmation ?? true,
            showLocation:
              liveBranchSettings?.settings?.columnsVisibility?.showLocation ??
              true,
            showTattleNotes:
              liveBranchSettings?.settings?.columnsVisibility
                ?.showTattleNotes ?? true,
            showTruck:
              liveBranchSettings?.settings?.columnsVisibility?.showTruck ??
              true,
            showHighPriority:
              liveBranchSettings?.settings?.columnsVisibility
                ?.showHighPriority ?? true,
          },
          // drivers/tech permissions
          driversPermissions: {
            canCreateDeliveryOrders:
              liveBranchSettings?.settings?.driversPermissions
                ?.canCreateDeliveryOrders ?? true,
            canAssignDeliveryOrders:
              liveBranchSettings?.settings?.driversPermissions
                ?.canAssignDeliveryOrders ?? true,
            canEditDeliveryOrders:
              liveBranchSettings?.settings?.driversPermissions
                ?.canEditDeliveryOrders ?? true,
            canDeleteDeliveryOrders:
              liveBranchSettings?.settings?.driversPermissions
                ?.canDeleteDeliveryOrders ?? true,
            canMoveDeliveryOrders:
              liveBranchSettings?.settings?.driversPermissions
                ?.canMoveDeliveryOrders ?? false,
            canCreateCustomers:
              liveBranchSettings?.settings?.driversPermissions
                ?.canCreateCustomers ?? true,
            canEditCustomers:
              liveBranchSettings?.settings?.driversPermissions
                ?.canEditCustomers ?? true,
            // canDeleteCustomers:
            //   liveBranchSettings?.settings?.driversPermissions
            //     ?.canDeleteCustomers ?? true,
          },
          // work alone settings
          workAloneSettings: {
            requireCheckIns:
              liveBranchSettings?.settings?.workAloneSettings?.requireCheckIns ?? false,
            scheduledCheckInsStartTime:
              liveBranchSettings?.settings?.workAloneSettings?.scheduledCheckInsStartTime ?? "06:00",
            scheduledCheckInsEndTime:
              liveBranchSettings?.settings?.workAloneSettings?.scheduledCheckInsEndTime ?? "19:00",
          },
        }}
        onSubmit={handleSubmit}
      >
        <Card>
          <Card.Header>
            <h2 className="card-title">General Settings</h2>
          </Card.Header>
          <Card.Body>
            <Row>
              <Col lg={4}>
                <FormSelectField
                  itemLabelProperty="text"
                  items={timezones}
                  itemValueProperty="value"
                  label="Timezone"
                  name="timeZoneId"
                />
              </Col>
            </Row>
          </Card.Body>
        </Card>

        <Card
          visible={!!branchModules?.workOrders || !!branchModules?.directDrops}
        >
          <Card.Header>
            <h2 className="card-title">Delivery Orders Settings</h2>
          </Card.Header>
          <Card.Body>
            <Row>
              <Col lg={4}>
                <FormTextField
                  info="Pre-set new direct drops quantity"
                  label="Direct Drops Liters"
                  name="directDropDefaultQuantity"
                  type="number"
                />
              </Col>
              <Col lg={4}>
                <FormTextField
                  info="How many hours should completed orders stay in the list?"
                  label="Hide Completed Orders Delay"
                  name="hideCompletedOrderDelay"
                  type="number"
                />
              </Col>
            </Row>

            <Row>
              <Col lg={4}>
                <FormCheckboxField
                  description="Yes, auto-assign orders"
                  info="New orders will be automatically assigned to the last driver that went to the same customer"
                  label="Assign Orders Automatically"
                  name="autoAssignOrders"
                />
              </Col>
              <Col lg={4}>
                <FormCheckboxField
                  description="Always convert Gallons to Liters"
                  info="You can enter values in G but the app will convert them to L"
                  label="Convert to L"
                  name="alwaysConvertToLiters"
                />
              </Col>
            </Row>

            <Row>
              <Col lg={4}>
                <FormSelectField
                  info="Only applies to ACTIVE orders"
                  itemLabelProperty="text"
                  items={workOrderColumns}
                  itemValueProperty="value"
                  label="Sort Delivery Orders By"
                  name="workOrdersSortColumnIndex"
                />
              </Col>
              <Col lg={4}>
                <FormSelectField
                  info="Only applies to ACTIVE orders"
                  itemLabelProperty="text"
                  items={sortDirectionItems}
                  itemValueProperty="value"
                  label="Sort Direction"
                  name="workOrdersSortDirection"
                />
              </Col>
            </Row>

            <Row>
              <Col lg={4}>
                <FormSelectField
                  info="Only applies to ACTIVE orders"
                  itemLabelProperty="text"
                  items={serviceOrderColumns}
                  itemValueProperty="value"
                  label="Sort Service Orders By"
                  name="serviceOrdersSortColumnIndex"
                />
              </Col>
              <Col lg={4}>
                <FormSelectField
                  info="Only applies to ACTIVE orders"
                  itemLabelProperty="text"
                  items={sortDirectionItems}
                  itemValueProperty="value"
                  label="Sort Direction"
                  name="serviceOrdersSortDirection"
                />
              </Col>
            </Row>

            <Row>
              <Col lg={4}>
                <FormCheckboxField
                  description="Lock the Assigned To field by default"
                  info="DEFAULT: Drivers won't be able to reassign delivery orders"
                  label="Automatically Lock Drivers/Techs"
                  name="automaticallyLockDrivers"
                />
              </Col>
            </Row>

            <Row>
              <Col>
                <AppFormLabel label="Delivery Orders and Direct Drops Columns" />
                <FieldInfo>
                  Uncheck the columns that you wish to hide.
                </FieldInfo>
                <Row>
                  <Col>
                    <label className="ws-nowrap">Customer</label>
                    <FormCheckboxField name="columnsVisibility.showCustomer" />
                  </Col>
                  <Col>
                    <label className="ws-nowrap">Notes</label>
                    <FormCheckboxField name="columnsVisibility.showNotes" />
                  </Col>
                  <Col>
                    <label className="ws-nowrap">Products</label>
                    <FormCheckboxField name="columnsVisibility.showProducts" />
                  </Col>
                  <Col>
                    <label className="ws-nowrap">Entered on</label>
                    <FormCheckboxField name="columnsVisibility.showCreatedDate" />
                  </Col>
                  <Col>
                    <label className="ws-nowrap">Driver</label>
                    <FormCheckboxField name="columnsVisibility.showAssignedTo" />
                  </Col>
                  <Col>
                    <label className="ws-nowrap">Status</label>
                    <FormCheckboxField name="columnsVisibility.showOrderStatus" />
                  </Col>
                  <Col>
                    <label className="ws-nowrap">Due on</label>
                    <FormCheckboxField name="columnsVisibility.showDueDate" />
                  </Col>
                  <Col>
                    <label className="ws-nowrap">Completed on</label>
                    <FormCheckboxField name="columnsVisibility.showCompletedDate" />
                  </Col>
                  <Col>
                    <label className="ws-nowrap">Completed by</label>
                    <FormCheckboxField name="columnsVisibility.showCompletedBy" />
                  </Col>
                  <Col>
                    <label className="ws-nowrap">Location info</label>
                    <FormCheckboxField name="columnsVisibility.showLocation" />
                  </Col>
                  <Col>
                    <label className="ws-nowrap">Confirmation info</label>
                    <FormCheckboxField name="columnsVisibility.showConfirmation" />
                  </Col>
                  <Col>
                    <label className="ws-nowrap">Tattle notes</label>
                    <FormCheckboxField name="columnsVisibility.showTattleNotes" />
                  </Col>
                  <Col>
                    <label className="ws-nowrap">Unit</label>
                    <FormCheckboxField name="columnsVisibility.showTruck" />
                  </Col>
                  <Col>
                    <label className="ws-nowrap">High Priority</label>
                    <FormCheckboxField name="columnsVisibility.showHighPriority" />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Card.Body>
        </Card>

        <Card>
          <Card.Header>
            <h2 className="card-title">
              Drivers/Technicians Settings & Permissions
            </h2>
          </Card.Header>
          <Card.Body>
            <Row>
              <Col>
                <AppFormLabel label="Permissions on Orders" />
                <Row>
                  <Col md={2}>
                    <FormCheckboxField
                      description="Create"
                      name="driversPermissions.canCreateDeliveryOrders"
                    />
                  </Col>
                  <Col md={2}>
                    <FormCheckboxField
                      description="Assign"
                      info="Can always assign to self"
                      name="driversPermissions.canAssignDeliveryOrders"
                    />
                  </Col>
                  <Col md={2}>
                    <FormCheckboxField
                      description="Edit"
                      name="driversPermissions.canEditDeliveryOrders"
                    />
                  </Col>
                  <Col md={2}>
                    <FormCheckboxField
                      description="Delete"
                      name="driversPermissions.canDeleteDeliveryOrders"
                    />
                  </Col>
                  <Col md={2}>
                    <FormCheckboxField
                      description="Move"
                      name="driversPermissions.canMoveDeliveryOrders"
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col>
                <AppFormLabel label="Permissions on Customers" />
                <Row>
                  <Col md={2}>
                    <FormCheckboxField
                      description="Create"
                      name="driversPermissions.canCreateCustomers"
                    />
                  </Col>
                  <Col md={2}>
                    <FormCheckboxField
                      description="Edit"
                      name="driversPermissions.canEditCustomers"
                    />
                  </Col>
                  {/* <Col md={2}>
                    <FormCheckboxField
                      description="Delete"
                      name="driversPermissions.canDeleteCustomers"
                    />
                  </Col> */}
                </Row>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormCheckboxField
                  description="Collect Drivers GPS Info"
                  label="GPS Settings"
                  name="enableGpsTracking"
                />
              </Col>
            </Row>
          </Card.Body>
        </Card>

        <Card visible={!!branchModules?.workAlone}>
          <Card.Header>
            <h2 className="card-title">Work Alone Settings</h2>
          </Card.Header>
          <Card.Body>
            <Row>
              <Col>
                <FormCheckboxField
                  description="Require Check-ins"
                  info="Every 2 hours"
                  label="Scheduled Check-ins"
                  name="workAloneSettings.requireCheckIns"
                />
              </Col>
              <Col>
                <FormTimePickerField
                  label="Start Time"
                  name="workAloneSettings.scheduledCheckInsStartTime"
                  placeholder="Select a time"
                />
              </Col>
              <Col>
                <FormTimePickerField
                  label="End Time"
                  name="workAloneSettings.scheduledCheckInsEndTime"
                  placeholder="Select a time"
                />
              </Col>
            </Row>
          </Card.Body>
        </Card>

        <div className="form-group mb-0 fixed-to-bottom">
          <Row>
            <Col lg={2} md={4} sm={6}>
              <SubmitButton className="btn-block" disabled={busy}>
                Save
              </SubmitButton>
            </Col>
          </Row>
        </div>
      </AppForm>
    </Page>
  );
};

export default SettingsPage;
