import { AuthContext, LoadingContext } from "../../context/contextProvider";
import { Navigate, useLocation, useParams } from "react-router-dom";

import { AppRoutes } from "../../constants/appRoutes";
import DashboardIcon from "@mui/icons-material/Dashboard";
import EditIcon from "@mui/icons-material/Edit";
import EventRepeatIcon from "@mui/icons-material/EventRepeat";
import React from "react";
import ScheduledOrderFormPage from "./ScheduledOrderFormPage";
import Services from "../../services/services";
import { ThemeColorsEnum } from "../../constants/enums";
import { useContext } from "react";
import useCurrentUserInfo from "../../hooks/useCurrentUserInfo";
import { useEffect } from "react";
import { useState } from "react";

const breadcrumbs = [
  {
    IconComponent: DashboardIcon,
    title: "Dashboard",
    to: AppRoutes.dashboard,
  },
  {
    IconComponent: EventRepeatIcon,
    title: "Scheduled Orders",
    to: AppRoutes.scheduledOrders,
  },
  {
    IconComponent: EditIcon,
    title: "Edit",
  },
];

const ScheduledOrderEditPage = (props) => {
  const { id } = useParams();
  const { state } = useLocation();
  const [scheduledOrder, setScheduledOrder] = useState();

  const { setLoading } = useContext(LoadingContext);
  const { branchModules } = useContext(AuthContext);
  const userInfo = useCurrentUserInfo();

  useEffect(() => {
    setScheduledOrder(state?.scheduledOrder);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (id && !state?.scheduledOrder) getScheduledOrderAsync(id);
  }, [id]); // eslint-disable-line react-hooks/exhaustive-deps

  const getScheduledOrderAsync = async (id) => {
    setLoading(true);
    const response = await Services.api.workOrders.get(id);
    setLoading(false);

    if (!response.ok)
      return Services.utility.toast(
        "Could not fetch the schedule",
        ThemeColorsEnum.error
      );

    setScheduledOrder(response.data);
  };

  if (!branchModules?.workOrders || !userInfo.canCreateDeliveryOrders)
    return (
      <Navigate
        to={{
          pathname: AppRoutes.forbidden,
        }}
      />
    );

  return (
    <ScheduledOrderFormPage
      breadcrumbs={breadcrumbs}
      title="Scheduled Orders - Edit"
      scheduledOrder={scheduledOrder}
    />
  );
};

export default ScheduledOrderEditPage;
