import * as Yup from "yup";

import React, { useEffect, useState } from "react";

import { StatusCodes } from "http-status-codes";
import { FormGroup } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import logo from "../../assets/img/nss-logo.svg";
import CopyrightFooter from "../../components/CopyrightFooter";
import AppForm from "../../components/forms/Form";
import AppFormLabel from "../../components/forms/FormLabel";
import FormTextField from "../../components/forms/FormTextField";
import SubmitButton from "../../components/forms/SubmitButton";
import { AppRoutes } from "../../constants/appRoutes";
import { ThemeColorsEnum } from "../../constants/enums";
import useAuthentication from "../../hooks/useAuthentication";
import Services from "../../services/services";
import GuestPage from "../GuestPage";

let validationSchema = Yup.object().shape({
  username: Yup.string().required().label("Username"),
  password: Yup.string().required().label("Password"),
});

const LoginPage = () => {
  const [apiError, setApiError] = useState();
  const [busy, setBusy] = useState(false);
  const [twoFARequired, setTwoFARequired] = useState(false);

  const authentication = useAuthentication();
  const { search: queryParams } = useLocation();

  const urlParams = new URLSearchParams(queryParams);

  const unauthorized =
    urlParams.get("status") === StatusCodes.UNAUTHORIZED.toString();
  const reasonPhrase = urlParams.get("reasonPhrase");

  if (twoFARequired)
    validationSchema = validationSchema.concat(
      Yup.object({
        authKey: Yup.string().required().min(1).label("Security code"),
      })
    );

  useEffect(() => {
    if (unauthorized)
      Services.utility.toast(
        reasonPhrase ?? "Your session expired.",
        ThemeColorsEnum.warning
      );
  }, [unauthorized]);

  const handleSubmit = async ({ username, password, authKey }) => {
    const payload = {
      username,
      password,
      ...(twoFARequired && { authKey }),
    };

    setBusy(true);
    const response = await authentication.logIn(payload);
    setBusy(false);

    switch (response.status) {
      case StatusCodes.ACCEPTED:
        Services.utility.toast(
          "The security code was sent to your email and it will expire in a few minutes."
        );
        return setTwoFARequired(true); // TWO-FA code required

      case StatusCodes.NOT_FOUND:
        return setApiError("Invalid credentials.");

      case StatusCodes.BAD_REQUEST:
      case StatusCodes.TOO_MANY_REQUESTS:
        return setApiError(Services.log.extractErrorMessage(response));

      case StatusCodes.OK:
        return setApiError(""); // SUCCESS
      // return (window.location = AppRoutes.dashboard);

      case StatusCodes.UNAUTHORIZED:
      case StatusCodes.FORBIDDEN:
        return setApiError(Services.log.extractErrorMessage(response));

      default:
        return setApiError(response.problem);
    }
  };

  return (
    <GuestPage className="body-sign">
      <div className="center-sign">
        <a href="/" className="logo float-left">
          <img src={logo} height="70" alt="Porto Admin" />
        </a>

        <div className="panel card-sign">
          <div className="card-title-sign mt-3 text-end">
            <h2 className="title text-uppercase font-weight-bold m-0">
              <i className="bx bx-user-circle me-1 text-6 position-relative top-5"></i>
              Sign In
            </h2>
          </div>
          <div className="card-body">
            <AppForm
              initialValues={{ username: "", password: "", authKey: "" }}
              onSubmit={handleSubmit}
              validationSchema={validationSchema}
            >
              <FormGroup className="form-group mb-3">
                <FormTextField
                  name="username"
                  label="Username"
                  icon="bx bx-user text-4"
                  required
                  size="lg"
                />
                <FormTextField
                  icon="bx bx-lock text-4"
                  LabelComponent={
                    <div className="clearfix">
                      <AppFormLabel
                        className="float-left"
                        label="Password"
                        required
                      />
                    </div>
                  }
                  name="password"
                  size="lg"
                  type="password"
                />
              </FormGroup>

              {twoFARequired && (
                <FormGroup className="form-group mb-3">
                  <FormTextField
                    icon="bx bx-key text-4"
                    label="Security Code"
                    name="authKey"
                    required
                    size="lg"
                  />

                  <div>
                    The security code was sent to your email and it will expire
                    in a few minutes.
                  </div>
                </FormGroup>
              )}

              <div className="row">
                <div className="col-sm-3">
                  {/* <div className="checkbox-custom checkbox-default">
                      <input
                        id="RememberMe"
                        name="rememberme"
                        type="checkbox"
                      />
                      <label htmlFor="RememberMe">Remember Me</label>
                    </div> */}
                    <Link to={AppRoutes.forgotPassword} className="float-end">
                        Forgot Password?
                    </Link>
                </div>

                <div className="col-sm-9 text-end">
                      
                  <SubmitButton loading={busy}>Sign In</SubmitButton>
                </div>
              </div>

              {apiError && <p className="text-danger">{apiError}</p>}

              {/* <span className="mt-3 mb-3 line-thru text-center text-uppercase">
                <span>or</span>
              </span> */}

              {/* <div className="mb-1 text-center">
                <a className="btn btn-facebook mb-3 ml-1 me-1" href="#">
                  Connect with <i className="fab fa-facebook-f"></i>
                </a>
                <a className="btn btn-twitter mb-3 ml-1 me-1" href="#">
                  Connect with <i className="fab fa-twitter"></i>
                </a>
              </div> */}

              {/* <p className="text-center">
                Don't have an account yet?
                <a href="pages-signup.html">Sign Up!</a>
              </p> */}
            </AppForm>
          </div>
        </div>

        <CopyrightFooter />
      </div>
    </GuestPage>
  );
};

export default LoginPage;
