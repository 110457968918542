import React, { useContext, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { RolesEnum, ThemeColorsEnum } from "../../../constants/enums";
import {
  AuthContext,
  LoadingContext,
  ModalContext,
} from "../../../context/contextProvider";

import Services from "../../../services/services";
import LoadingButton from "../../buttons/LoadingButton";
import Card from "../../cards/Card";
import FieldInfo from "../../forms/FieldInfo";
import AppForm from "../../forms/Form";
import FormCheckboxField from "../../forms/FormCheckboxField";
import AppFormLabel from "../../forms/FormLabel";
import SubmitButton from "../../forms/SubmitButton";

const UserPreferencesModal = ({ onSave = () => {}, user, ...otherProps }) => {
  const [userSettings, setUserSettings] = useState();

  const { branchModules } = useContext(AuthContext);
  const { closeModal } = useContext(ModalContext);
  const { setLoading } = useContext(LoadingContext);

  const rolesArray = !!user?.roleNames
    ? user?.roleNames?.split(",")
    : user?.roles?.split(",");
  const hasDriverRole = rolesArray?.find((x) => x.trim() === RolesEnum.driver);
  const isAdmin = rolesArray?.find(
    (x) => x.trim() === RolesEnum.superAdmin || x.trim() === RolesEnum.admin
  );

  useEffect(() => {
    getUserSettingsAsync();

    return () => {};
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getUserSettingsAsync = async () => {
    setLoading(true);
    const response = await Services.api.settings.getUserSettings(user?.id);
    setLoading(false);

    if (!response.ok)
      return Services.utility.toast(
        "Could not get user preferences",
        ThemeColorsEnum.error
      );

    setUserSettings(response.data);
  };

  const handleSubmit = (values) => {
    closeModal();
    onSave({ ...userSettings, settings: values });
  };

  return (
    <AppForm
      initialValues={{
        isPushNotificationsEnabled:
          userSettings?.settings?.isPushNotificationsEnabled,
        isEditOrderNotificationEnabled:
          userSettings?.settings?.isEditOrderNotificationEnabled,
        isAssignOrderNotificationEnabled:
          userSettings?.settings?.isAssignOrderNotificationEnabled,
        isStartedOrderNotificationEnabled:
          userSettings?.settings?.isStartedOrderNotificationEnabled,
        isCompleteOrderNotificationEnabled:
          userSettings?.settings?.isCompleteOrderNotificationEnabled,
        isDeleteOrderNotificationEnabled:
          userSettings?.settings?.isDeleteOrderNotificationEnabled,
        isServicesCreateOrderNotificationsEnabled:
          userSettings?.settings?.isServicesCreateOrderNotificationsEnabled,
        isServicesEditOrderNotificationEnabled:
          userSettings?.settings?.isServicesEditOrderNotificationEnabled,
        isServicesAssignOrderNotificationEnabled:
          userSettings?.settings?.isServicesAssignOrderNotificationEnabled,
        isServicesStartedOrderNotificationEnabled:
          userSettings?.settings?.isServicesStartedOrderNotificationEnabled,
        isServicesCompleteOrderNotificationEnabled:
          userSettings?.settings?.isServicesCompleteOrderNotificationEnabled,
        isServicesDeleteOrderNotificationEnabled:
          userSettings?.settings?.isServicesDeleteOrderNotificationEnabled,
        isCheckInReminderNotificationEnabled:
          userSettings?.settings?.isCheckInReminderNotificationEnabled,
        isMissedCheckInsNotificationEnabled:
          userSettings?.settings?.isMissedCheckInsNotificationEnabled,
        hideDirectDrops: userSettings?.settings?.hideDirectDrops,
        hideServiceOrders: userSettings?.settings?.hideServiceOrders,
        hideWorkOrders: userSettings?.settings?.hideWorkOrders,
        twoFactorEnabled: userSettings?.settings?.twoFactorEnabled,
      }}
      onSubmit={handleSubmit}
    >
      <Card.Body>
        <AppFormLabel label="Security" />
        <Row>
          <Col>
            <FormCheckboxField
              description="Two Factor Authentication"
              name="twoFactorEnabled"
            />
          </Col>
        </Row>

        {!!(branchModules?.workOrders || branchModules?.directDrops) && (
          <>
            <AppFormLabel label="Delivery Orders & Direct Drops Notifications" />
            <Row>
              <Col>
                <FormCheckboxField
                  description="Create"
                  name="isPushNotificationsEnabled"
                />
              </Col>
              <Col>
                <FormCheckboxField
                  description="Edit"
                  name="isEditOrderNotificationEnabled"
                />
              </Col>
              <Col>
                <FormCheckboxField
                  description="Assign"
                  name="isAssignOrderNotificationEnabled"
                />
              </Col>
              <Col>
                <FormCheckboxField
                  description="Start"
                  name="isStartedOrderNotificationEnabled"
                />
              </Col>
              <Col>
                <FormCheckboxField
                  description="Complete"
                  name="isCompleteOrderNotificationEnabled"
                />
              </Col>
              <Col>
                <FormCheckboxField
                  description="Delete"
                  name="isDeleteOrderNotificationEnabled"
                />
              </Col>
            </Row>
          </>
        )}

        {!!branchModules?.serviceOrders && (
          <>
            <AppFormLabel label="Service Orders Notifications" />
            <Row>
              <Col>
                <FormCheckboxField
                  description="Create"
                  name="isServicesCreateOrderNotificationsEnabled"
                />
              </Col>
              <Col>
                <FormCheckboxField
                  description="Edit"
                  name="isServicesEditOrderNotificationEnabled"
                />
              </Col>
              <Col>
                <FormCheckboxField
                  description="Assign"
                  name="isServicesAssignOrderNotificationEnabled"
                />
              </Col>
              <Col>
                <FormCheckboxField
                  description="Start"
                  name="isServicesStartedOrderNotificationEnabled"
                />
              </Col>
              <Col>
                <FormCheckboxField
                  description="Complete"
                  name="isServicesCompleteOrderNotificationEnabled"
                />
              </Col>
              <Col>
                <FormCheckboxField
                  description="Delete"
                  name="isServicesDeleteOrderNotificationEnabled"
                />
              </Col>
            </Row>
          </>
        )}

        {!!branchModules?.workAlone && (
          <>
            <AppFormLabel label="Work Alone Notifications" />
            <Row>
              {!!hasDriverRole && (
                <Col>
                  <FormCheckboxField
                    description="Check-in Reminders"
                    name="isCheckInReminderNotificationEnabled"
                  />
                </Col>
              )}
              {!!isAdmin && (
                <Col>
                  <FormCheckboxField
                    description="Missed Check-ins Notifications"
                    name="isMissedCheckInsNotificationEnabled"
                  />
                </Col>
              )}
            </Row>
          </>
        )}

        {!!(
          branchModules?.workOrders ||
          branchModules?.directDrops ||
          branchModules?.serviceOrders
        ) && (
          <>
            <AppFormLabel label="Show/Hide Order Types" />
            <Row>
              {!!branchModules?.workOrders && (
                <Col lg={6}>
                  <FormCheckboxField
                    description="Hide Delivery Orders"
                    name="hideWorkOrders"
                  />
                </Col>
              )}
              {!!branchModules?.directDrops && (
                <Col lg={6}>
                  <FormCheckboxField
                    description="Hide Direct Drops"
                    name="hideDirectDrops"
                  />
                </Col>
              )}
              {!!branchModules?.serviceOrders && (
                <Col lg={6}>
                  <FormCheckboxField
                    description="Hide Service Orders"
                    name="hideServiceOrders"
                  />
                </Col>
              )}
            </Row>
            <FieldInfo>These settings apply to you only.</FieldInfo>
          </>
        )}
      </Card.Body>
      <Card.Footer>
        <Row className="mt-3">
          <Col className="text-end">
            <LoadingButton
              className="me-2"
              onClick={() => {
                closeModal();
              }}
              padding={10}
              color={ThemeColorsEnum.quaternary}
            >
              Cancel
            </LoadingButton>
            <SubmitButton>Save</SubmitButton>
          </Col>
        </Row>
      </Card.Footer>
    </AppForm>
  );
};

export default UserPreferencesModal;
