import { useContext, useEffect, useState } from "react";

import { AuthContext } from "../context/contextProvider";
import { ClientTypesEnum } from "../constants/enums";

const useBranchInfo = () => {
  const [isPetroleumBranch, setIsPetroleumBranch] = useState(false);
  const [isPropaneBranch, setIsPropaneBranch] = useState(false);
  const [isServiceBranch, setIsServiceBranch] = useState(false);

  const { currentClient } = useContext(AuthContext);

  useEffect(() => {
    getBranchInfoAsync();
  }, [currentClient]); // eslint-disable-line react-hooks/exhaustive-deps

  const getBranchInfoAsync = async () => {
    setIsPropaneBranch(currentClient?.typeId === ClientTypesEnum.propane);
    setIsPetroleumBranch(currentClient?.typeId === ClientTypesEnum.petroleum);
    setIsServiceBranch(currentClient?.typeId === ClientTypesEnum.service);
  };
  
  return {
    isPetroleumBranch,
    isPropaneBranch,
    isServiceBranch,
  };
};

export default useBranchInfo;
