import { Col, Form, Row } from "react-bootstrap";
import React, { useEffect, useState } from "react";

import AppSelect from "./Select";
import Enumerable from "linq";

const TruckProduct = ({
  className,
  onBlur = () => {},
  onSelected = () => {},
  products,
  truckProduct,
  ...otherProps
}) => {
  // state variables-------------
  const [totalLoad, setTotalLoad] = useState(
    truckProduct?.totalLoad?.toString() ?? ""
  );
  const [currentLoad, setCurrentLoad] = useState(
    truckProduct?.currentLoad?.toString() ?? ""
  );
  const [productId, setProductId] = useState(truckProduct?.productId ?? "");
  // state variables-------------

  const sortedProducts = Enumerable.from(products)
    .orderByDescending((x) => x.order !== null)
    .thenBy((x) => x.name)
    .thenBy((x) => x.order)
    .toArray();

  useEffect(() => {
    setTotalLoad(truckProduct?.totalLoad?.toString() ?? "");
    setCurrentLoad(truckProduct?.currentLoad?.toString() ?? "");
    setProductId(truckProduct?.productId ?? "");
  }, [truckProduct]);

  useEffect(() => {
    const productData = {
      totalLoad: totalLoad,
      currentLoad: currentLoad,
      productId: productId,
    };
    onSelected(productData);
  }, [totalLoad, currentLoad, productId]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Row className={className}>
      <Col className="pe-1">
        <AppSelect
          itemLabelProperty="shortName"
          items={sortedProducts}
          itemValueProperty="id"
          name="productId"
          onBlur={onBlur}
          onChange={(item) => {
            setProductId(item?.id);
          }}
          placeholder="Product"
          title="Product"
          value={productId}
        />
      </Col>
      <Col className="px-1">
        <Form.Control
          name="totalLoad"
          onBlur={onBlur}
          onChange={(event) => setTotalLoad(event.target?.value)}
          placeholder="Total load"
          type="number"
          value={totalLoad}
        />
      </Col>
      <Col className="px-1">
        <Form.Control
          name="currentLoad"
          onBlur={onBlur}
          onChange={(event) => setCurrentLoad(event.target?.value)}
          placeholder="Current load"
          type="number"
          value={currentLoad}
        />
      </Col>
    </Row>
  );
};

export default TruckProduct;
