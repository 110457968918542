import React, { useContext, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import {
  SortDirections,
  StatusEnum,
  ThemeColorsEnum,
  UserColumnsEnum,
} from "../../constants/enums";
import {
  AuthContext,
  LoadingContext,
  ModalContext,
} from "../../context/contextProvider";
import { Search, SearchFilterRequest } from "../../models/searchFilterRequest";

import DashboardIcon from "@mui/icons-material/Dashboard";
import PeopleIcon from "@mui/icons-material/People";
import { Navigate } from "react-router-dom";
import AddButton from "../../components/buttons/AddButton";
import Card from "../../components/cards/Card";
import EmptyDataBox from "../../components/dataTables/EmptyDataBox";
import NSDataTable from "../../components/dataTables/NSDataTable";
import NSDataTablePagination from "../../components/dataTables/NSDataTablePagination";
import { AppRoutes } from "../../constants/appRoutes";
import Constants from "../../constants/constants";
import useCurrentUserInfo from "../../hooks/useCurrentUserInfo";
import useUsersDataTable from "../../hooks/useUsersDataTable";
import Services from "../../services/services";
import Page from "../Page";
import UserDetails from "./UserDetails";

const breadcrumbs = [
  {
    IconComponent: DashboardIcon,
    title: "Dashhboard",
    to: AppRoutes.dashboard,
  },
  {
    IconComponent: PeopleIcon,
    title: "Users",
  },
];
const UsersPage = ({ status = StatusEnum.active, ...otherProps }) => {
  const { setLoading } = useContext(LoadingContext);
  const { currentClient, cache, setCache } = useContext(AuthContext);
  const { showModal } = useContext(ModalContext);

  // data-tables - start
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(Constants.defaults.rowsPerPage);
  const [paginationData, setPaginationData] = useState({
    from: null,
    pages: null,
    records: null,
    to: null,
  });
  const [searchValue, setSearchValue] = useState(cache?.users?.search ?? "");
  const [sortOptions, setSortOptions] = useState({
    column: cache?.users?.sortColumn ?? UserColumnsEnum.name,
    direction: cache?.users?.sortDirection ?? SortDirections.ascending,
  });
  // data-tables - end
  const [users, setUsers] = useState([]);

  const userInfo = useCurrentUserInfo();

  const title =
    !status || status === StatusEnum.active ? "Users" : "Users - Deleted";
  const dataTableInfo = useUsersDataTable(status, handleDeleted);

  useEffect(() => {
    setCache({
      ...cache,
      users: {
        currentPage: currentPage,
        pageSize: pageSize,
        search: searchValue,
        sortColumn: sortOptions.column,
        sortDirection: sortOptions.direction,
      },
    });
    getUsersAsync((currentPage - 1) * pageSize, pageSize, searchValue);
    return () => {};
  }, [currentClient, currentPage, pageSize, searchValue, sortOptions, status]); // eslint-disable-line react-hooks/exhaustive-deps

  const getUsersAsync = async (
    start = 0,
    take = Constants.defaults.rowsPerPage,
    searchText = null
  ) => {
    // filter
    const filter = new SearchFilterRequest();
    filter.search = new Search(searchText);
    filter.start = start;
    filter.length = take;
    filter.status = status;
    filter.order[0].column = sortOptions.column ?? UserColumnsEnum.name;
    filter.order[0].dir = sortOptions.direction ?? SortDirections.ascending;

    setLoading(true);
    const response = await Services.api.users.getUsers(filter);
    setLoading(false);

    if (!response.ok)
      return Services.utility.toast(
        "Could not fetch the users",
        ThemeColorsEnum.error
      );

    setUsers(response.data?.data);
    setPaginationData({
      from: (currentPage - 1) * pageSize + 1,
      pages: response.data?.pagesTotal,
      records: response.data?.recordsTotal,
      to:
        currentPage * pageSize > response.data?.recordsTotal
          ? response.data?.recordsTotal
          : currentPage * pageSize,
    });
  };

  function handleDeleted(product) {
    setUsers(users.filter((x) => x.id !== product.id));
  }

  const handlePageChange = async (page) => {
    setCurrentPage(page);
  };

  const handleRowClick = (event, row) => {
    event.stopPropagation();
    // opend the details modal
    showModal(row.fullName, <UserDetails user={row} />, [
      {
        color: ThemeColorsEnum.quaternary,
        title: "Close",
      },
    ]);
  };

  const handleRowsPerPageChange = (newPerPage, currentPage) => {
    setCurrentPage(1);
    setPageSize(newPerPage);
  };

  const handleSearch = (searchValue) => {
    setSearchValue(searchValue);
  };

  const handleSort = (column, direction) => {
    setCurrentPage(1);
    setSortOptions({ column: column, direction: direction });
  };

  if (!userInfo?.isAdmin)
    return (
      <Navigate
        to={{
          pathname: AppRoutes.forbidden,
        }}
      />
    );

  return (
    <Page breadcrumbs={breadcrumbs} title={title}>
      <Row>
        <Col>
          <Card>
            <Card.Body>
              <div className="dataTables_wrapper dt-bootstrap5 no-footer">
                {/* **** BOOTSTRAP PAGINATION **** */}
                <NSDataTablePagination
                  from={paginationData?.from}
                  lastPage={paginationData?.pages}
                  page={currentPage}
                  pageSize={pageSize}
                  total={paginationData?.records}
                  to={paginationData?.to}
                  onPageChange={handlePageChange}
                  onPageSizeChange={(newPerPage) =>
                    handleRowsPerPageChange(newPerPage, currentPage)
                  }
                />
                {/* **** DATA TABLE **** */}
                <NSDataTable
                  buttons={<AddButton to={AppRoutes.users_create} />}
                  className="dataTable table-no-more"
                  // dense
                  columns={dataTableInfo.columns}
                  conditionalRowStyles={dataTableInfo.conditionalRowStyles}
                  data={users}
                  emptyDataComponent={<EmptyDataBox />}
                  onRowClick={handleRowClick}
                  onSearch={handleSearch}
                  onSort={handleSort}
                  paginate
                  search
                  searchValue={searchValue}
                  sortBy={sortOptions.column}
                  sortDirection={sortOptions.direction}
                />
                {/* **** BOOTSTRAP PAGINATION **** */}
                <NSDataTablePagination
                  from={paginationData?.from}
                  lastPage={paginationData?.pages}
                  page={currentPage}
                  pageSize={pageSize}
                  showRecordsInfo
                  total={paginationData?.records}
                  to={paginationData?.to}
                  onPageChange={handlePageChange}
                  onPageSizeChange={(newPerPage) =>
                    handleRowsPerPageChange(newPerPage, currentPage)
                  }
                />
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Page>
  );
};

export default UsersPage;
