import { create } from "apisauce";
import { StatusCodes } from "http-status-codes";
import {
  browserName,
  deviceType,
  fullBrowserVersion,
  osName,
  osVersion,
} from "react-device-detect";
import { toast } from "react-toastify";
import { AppRoutes } from "../constants/appRoutes";
import Endpoints from "../constants/endpoints";
import { CustomRequestHeadersEnum } from "../constants/enums";
import { LocalStorageKeys } from "../constants/storageKeys";
import localStorageService from "./localStorageService";
import logService from "./logService";

// define the api
const httpClient = create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
  withCredentials: false,
  headers: {
    // Accept: "*/*",
    Accept: "applications/json",
  },
});

httpClient.addAsyncRequestTransform(async (request) => {
  const accessToken = localStorageService.getProperty(
    LocalStorageKeys.accessToken
  );
  const deviceInfo = `${osName} ${osVersion} - ${browserName}`;

  // add the authorization token if present
  if (accessToken) request.headers["Authorization"] = `Bearer ${accessToken}`;

  request.headers[CustomRequestHeadersEnum.apiVersion] =
    process.env.REACT_APP_API_VERSION;
  request.headers[CustomRequestHeadersEnum.device] = deviceType;
  request.headers[CustomRequestHeadersEnum.deviceId] = fullBrowserVersion;
  request.headers[CustomRequestHeadersEnum.deviceInfo] = deviceInfo;
  request.headers[CustomRequestHeadersEnum.timeZoneOffset] =
    new Date().getTimezoneOffset();
});

httpClient.axiosInstance.interceptors.response.use(
  // SUCCESS
  (response) => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  // FAIL
  (error) => {
    console.log("HTTP error", error.response);
    // alert(JSON.stringify(error, null, 4));

    //************************** This is now handled in [refreshToken] *****************************/
    // if (error.response?.status === StatusCodes.UNAUTHORIZED) {
    //   localStorageService.clear();
    //   // redirect to log in
    //   // await authApiService.logOut(); // this creates an infinite loop because it always returns 401
    //   window.location = `${AppRoutes.login}?status=${StatusCodes.UNAUTHORIZED}&reasonPhrase=Session expired`;

    //   return error.response;
    //   // return Promise.reject(error.response);
    // }

    const expectedError =
      error.response?.status >= 400 && error.response?.status < 500;

    if (!expectedError) {
      logService.log(error);

      const message =
        logService.extractErrorMessage(error.response) ??
        logService.extractErrorMessage(error);

      toast.error(message, { theme: "colored" });

      return Promise.reject(message);
    }

    // return error;
    return Promise.resolve(error);
  }
);

const httpDelete = async (url, params = {}, axiosConfig = {}) => {
  let response = await httpClient.delete(url, params, axiosConfig);

  if (response.status === StatusCodes.UNAUTHORIZED) {
    const refreshTokenResponse = await refreshToken();

    if (refreshTokenResponse.ok)
      response = await httpClient.delete(url, params, axiosConfig);
  }

  return response;
};

/*
* Makes an asynchronous GET request to the given URL with optional
* query params and axiosConfig.
* If the response status code is UNAUTHORIZED, it will send a request
* to refresh the access token. If successful it will retry the request,
* otherwise it will redirect to log in, clearing the local storage.
*/
const httpGet = async (url, params = {}, axiosConfig = {}) => {
  let response = await httpClient.get(url, params, axiosConfig);

  if (response.status === StatusCodes.UNAUTHORIZED) {
    const refreshTokenResponse = await refreshToken();

    if (refreshTokenResponse.ok)
      response = await httpClient.get(url, params, axiosConfig);
  }

  return response;
};

const httpPatch = async (url, data = {}, axiosConfig = {}) => {
  let response = await httpClient.patch(url, sanitizeData(data), axiosConfig);

  if (response.status === StatusCodes.UNAUTHORIZED) {
    const refreshTokenResponse = await refreshToken();

    if (refreshTokenResponse.ok)
      response = await httpClient.patch(url, sanitizeData(data), axiosConfig);
  }

  return response;
};

const httpPost = async (url, data = {}, axiosConfig = {}) => {
  let response = await httpClient.post(url, sanitizeData(data), axiosConfig);

  if (response.status === StatusCodes.UNAUTHORIZED) {
    const refreshTokenResponse = await refreshToken();

    if (refreshTokenResponse.ok)
      response = await httpClient.post(url, sanitizeData(data), axiosConfig);
  }

  return response;
};

const httpPut = async (url, data = {}, axiosConfig = {}) => {
  let response = await httpClient.put(url, sanitizeData(data), axiosConfig);

  if (response.status === StatusCodes.UNAUTHORIZED) {
    const refreshTokenResponse = await refreshToken();

    if (refreshTokenResponse.ok)
      response = await httpClient.put(url, sanitizeData(data), axiosConfig);
  }

  return response;
};

const refreshToken = async () => {
  const accessToken = localStorageService.getProperty(
    LocalStorageKeys.accessToken
  );
  const refreshToken = localStorageService.getProperty(
    LocalStorageKeys.refreshToken
  );

  const payload = {
    access_token: accessToken,
    refresh_token: refreshToken,
  };
  

  const response = await httpClient.post(Endpoints.auth.refreshToken, payload);
  

  if (!response.ok) {
    localStorageService.clear();
    // redirect to log in
    window.location = `${AppRoutes.login}?status=${StatusCodes.UNAUTHORIZED}&reasonPhrase=Session expired`;

    return response;
    // return Promise.reject(error.response);
  }

  if (response.ok) {
    // save the access_token
    localStorageService.setProperty(
      LocalStorageKeys.accessToken,
      response.data?.access_token
    );

    // save the refresh_token
    localStorageService.setProperty(
      LocalStorageKeys.refreshToken,
      response.data?.refresh_token
    );
  }

  return response;
};

/**
 * Removes all empty or null fields from a json object
 * @param {Object} data Json data
 * @returns
 */
const sanitizeData = (data) => {
  Object.keys(data).forEach(
    (k) => !data[k] && data[k] !== undefined && delete data[k]
  );

  return data;
};

//-----------------------------------------//
const httpService = {
  get: httpGet,
  put: httpPut,
  patch: httpPatch,
  post: httpPost,
  delete: httpDelete,
};

export default httpService;
//-----------------------------------------//
