export const AppRoutes = {
  // admin
  admin: "/admin",
  modules: "/admin/modules",
  settings: "/admin/settings",
  alerts: "/admin/alerts",
  alerts_create: "/admin/alerts/create",
  alerts_edit: "/admin/alerts/:id/edit",

  // billing
  billing: "/billing",
  billingClients: "/billing/clients",
  billingClientBranches: "/billing/clients/:id/branches",
  billingBranchDetails: "/billing/branches/:id",
  billingUsers: "/billing/users",
  billingTrialUsers: "/billing/trial-users",

  // branches
  branches: "/branches",
  branches_create: "/branches/create",
  branches_edit: "/branches/:id/edit",

  contact: "/contact",

  // customers
  customers: "/customers",
  customers_create: "/customers/create",
  customers_edit: "/customers/:id/edit",
  customers_import: "/customers/import",
  customerLocations: "/customers/:id/locations",
  customerLocations_add: "/customers/:id/locations/add",
  customerLocations_edit: "/customers/:id/locations/:locationId/edit",
  customers_map: "/customers/map",

  dashboard: "/",

  // delivery orders
  deliveryOrders: "/delivery-orders",
  deliveryOrders_completed: "/delivery-orders/completed",
  deliveryOrders_create: "/delivery-orders/create",
  deliveryOrders_edit: "/delivery-orders/:id/edit",
  deliveryOrders_map: "/delivery-orders/map",

  // direct drops
  directDrops: "/direct-drops",
  directDrops_completed: "/direct-drops/completed",
  directDrops_create: "/direct-drops/create",
  directDrops_edit: "/direct-drops/:id/edit",
  directDrops_map: "/direct-drops/map",

  forgotPassword: "/account/forgot-password",
  resetPassword: "/account/reset-password",
  login: "/login",

  // products
  products: "/products",
  products_create: "/products/create",
  products_edit: "/products/:id/edit",

  // reports
  reports: "/reports",
  reports_dispatches: "/reports/dispatches",
  reports_services: "/reports/services",

  // schedules
  scheduledOrders: "/delivery-orders/schedules",
  scheduledOrders_create: "/delivery-orders/schedules/create",
  scheduledOrders_edit: "/delivery-orders/schedules/:id/edit",

  // service orders
  serviceOrders: "/service-orders",
  serviceOrders_completed: "/service-orders/completed",
  serviceOrders_create: "/service-orders/create",
  serviceOrders_edit: "/service-orders/:id/edit",
  serviceOrders_map: "/service-orders/map",

  // service order categories
  serviceOrderCategories: "/service-orders/categories",
  serviceOrderCategories_create: "/service-orders/categories/create",
  serviceOrderCategories_edit: "/service-orders/categories/:id/edit",

  // trucks
  trucks: "/trucks",
  trucks_create: "/trucks/create",
  trucks_edit: "/trucks/:id/edit",

  // tattles
  tattles: "/tattles",
  tattles_create: "/tattles/create",
  tattles_edit: "/tattles/:id/edit",

  userGuides: "/user-guides",

  // users
  users: "/users",
  users_create: "/users/create",
  users_deleted: "/users/deleted",
  users_edit: "/users/:id/edit",
  users_map: "/users/map",

  // work alone
  workAlone: "/work-alone",
  scheduledCheckIns: "/work-alone/scheduled-checkins",

  // errors
  forbidden: "/forbidden",
  notFound: "/not-found",

  test: "/test",
};
