import accountApiService from "./accountApiService";
import alertsApiService from "./alertsApiService";
import authApiService from "./authApiService";
import blillingApiService from "./blillingApiService";
import clientsApiService from "./clientsApiService";
import customersApiService from "./customersApiService";
import homeApiService from "./homeApiService";
import productsApiService from "./productsApiService";
import rolesApiService from "./rolesApiService";
import scheduledCheckInsApiService from "./scheduledCheckInsApiService";
import serviceCategoriesApiService from "./serviceCategoriesApiService";
import serviceOrdersApiService from "./serviceOrdersApiService";
import settingsApiService from "./settingsApiService";
import tattlesApiService from "./tattlesApiService";
import tripsApiService from "./tripsApiService";
import trucksApiService from "./trucksApiService";
import usersApiService from "./usersApiService";
import utilityApiService from "./utilityApiService";
import workOrdersApiService from "./workOrdersApiService";

//-----------------------------------------//
const apiService = {
  account: accountApiService,
  alerts: alertsApiService,
  auth: authApiService,
  billing: blillingApiService,
  clients: clientsApiService,
  customers: customersApiService,
  home: homeApiService,
  products: productsApiService,
  roles: rolesApiService,
  scheduledCheckIns: scheduledCheckInsApiService,
  serviceCategories: serviceCategoriesApiService,
  serviceOrders: serviceOrdersApiService,
  settings: settingsApiService,
  tattles: tattlesApiService,
  trips: tripsApiService,
  trucks: trucksApiService,
  users: usersApiService,
  utility: utilityApiService,
  workOrders: workOrdersApiService,
};

export default apiService;
//-----------------------------------------//
