import * as Yup from "yup";

import { UnitOfMeasurementsEnum } from "./enums";

const passwordSchema = Yup.string()
  .min(6, "Password is too short (min 6)")
  .max(15, "Password is too long (max 15)")
  .matches(/[\d]{1}/g, "Password must contain at least 1 digit")
  .label("Password");

const phoneNumberSchema = Yup.string()
  .min(10, "Phone number must be at least 10 digits")
  // .required()
  .matches(
    /^([0-9]( |-)?)?(\(?[0-9]{3}\)?|[0-9]{3})( |-)?([0-9]{3}( |-)?[0-9]{4}|[a-zA-Z0-9]{7})$/,
    "Enter a valid phone number"
  )
  .label("Phone number");

const petroleumOrderShape = {
  customerId: Yup.number()
    .required()
    .label("Customer")
    .typeError("Customer is a required field"),
  dueDate: Yup.date()
    .required()
    .label("Due date")
    .typeError("Due date is a required field"),
  productList: Yup.array()
    .of(
      Yup.object().shape({
        productId: Yup.number(),
        quantity: Yup.number().positive(),
        unitOfMeasurement: Yup.string(),
      })
    )
    .test({
      name: "validate-petroleum-products",
      message: null,
      test: (val, context) => validatePetroleumProducts(val),
    }),
  locationName: Yup.string().max(256).label("Location Notes"),
};

const propaneOrderShape = {
  customerId: Yup.number()
    .required()
    .label("Customer")
    .typeError("Customer is a required field"),
  dueDate: Yup.date()
    .required()
    .label("Due date")
    .typeError("Due date is a required field"),
  productList: Yup.array()
    .of(
      Yup.object().shape({
        currentPercentage: Yup.number().positive(),
        price: Yup.number().positive().nullable(),
        productId: Yup.number(),
        tankSize: Yup.number().positive(),
      })
    )
    .test({
      name: "validate-propane-products",
      message: null,
      test: (val, context) => validatePropaneProducts(val),
    }),
  locationName: Yup.string().max(256).label("Location Notes"),
};

const servicesOrOtherOrderShape = {
  customerId: Yup.number()
    .required()
    .label("Cuistomer")
    .typeError("Customer is a required field"),
  dueDate: Yup.date()
    .required()
    .label("Due Date")
    .typeError("Due date is a required field"),
  productList: Yup.array()
    .of(
      Yup.object().shape({
        price: Yup.number().positive().nullable(),
        productId: Yup.number(),
      })
    )
  .test({
    name: "validate-servicesOrOther-products",
    message: null,
    test: (val, context) => validateServiceProducts(val),
  }),
  locationName: Yup.string().max(256).label("Location Notes"),

};

const validatePetroleumProducts = (productsArray) => {
  const validProducts = productsArray?.filter(
    (x) =>
      x.productId &&
      x.unitOfMeasurement &&
      (x.unitOfMeasurement === UnitOfMeasurementsEnum.fillLiters ||
      x.unitOfMeasurement === UnitOfMeasurementsEnum.fillGallons
        ? true
        : x.quantity > 0)
  );

  if (validProducts?.length === 0)
    return new Yup.ValidationError(
      "Fill at least one product",
      null,
      "products"
    );

  return true;
};

const validatePropaneProducts = (productsArray) => {
  const validProducts = productsArray?.filter(
    (x) => x.currentPercentage && x.productId && x.tankSize
  );

  if (validProducts?.length === 0)
    return new Yup.ValidationError(
      "Fill at least one product",
      null,
      "products"
    );

  return true;
};

const validateServiceProducts = (productsArray) => {
  const validProducts = productsArray?.filter(
    (x) => x.price && x.productId
    
  );
  
  if (validProducts?.length === 0)
    return new Yup.ValidationError(
      "Fill at least one product",
      null,
      "products"
    );

    return true;
};

const userSchemaShape = {
  email: Yup.string().email().min(1).required().label("Email"),
  firstName: Yup.string().min(1).required().label("First name"),
  lastName: Yup.string().min(1).required().label("Last name"),
  phoneNumber: phoneNumberSchema.required(),
  userName: Yup.string().min(1).required().label("Username"),
  roles: Yup.array().required().label("Role(s)"),
  confirmPassword: Yup.string()
    .when("password", {
      is: (password) => password?.length > 0,
      then: Yup.string()
        .required()
        .oneOf([Yup.ref("password"), null], "Passwords must match"),
      otherwise: Yup.string()
        .optional()
        .oneOf([Yup.ref("password"), null], "Passwords must match"),
    })
    .label("Confirm password"),
};

const editUser = Yup.object().shape({
  ...userSchemaShape,
  password: passwordSchema,
});

const createUser = Yup.object().shape({
  ...userSchemaShape,
  password: passwordSchema.required(),
});

const Schemas = {
  createUser,
  editUser,
  fields: {
    phoneNumber: phoneNumberSchema,
  },
  petroleumOrderShape,
  propaneOrderShape,
  servicesOrOtherOrderShape,
};

export default Schemas;
