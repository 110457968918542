import * as Yup from "yup";

import { Col, Row } from "react-bootstrap";
import { LoadingContext, ModalContext } from "../../../context/contextProvider";
import React, { useContext, useEffect, useState } from "react";
import {
  ThemeColorsEnum,
  UnitOfMeasurementsEnum,
} from "../../../constants/enums";

import AppForm from "../../forms/Form";
import Card from "../../cards/Card";
import FormWorkOrderProducts from "../../forms/FormWorkOrderProducts";
import LoadingButton from "../../buttons/LoadingButton";
import Services from "../../../services/services";
import SubmitButton from "../../forms/SubmitButton";
import useBranchInfo from "../../../hooks/useBranchInfo";

const petroleumOrderValidationSchema = Yup.object().shape({
  productList: Yup.array()
    .of(
      Yup.object().shape({
        productId: Yup.number(),
        quantity: Yup.number().positive(),
        unitOfMeasurement: Yup.string(),
      })
    )
    .test({
      name: "validate-petroleum-products",
      message: null,
      test: (val, context) => validatePetroleumProducts(val),
    }),
});

const propaneOrderValidationSchema = Yup.object().shape({
  productList: Yup.array()
    .of(
      Yup.object().shape({
        currentPercentage: Yup.number().positive(),
        price: Yup.number().positive(),
        productId: Yup.number(),
        tankSize: Yup.number().positive(),
      })
    )
    .test({
      name: "validate-propane-products",
      message: null,
      test: (val, context) => validatePropaneProducts(val),
    }),
});

const serviceOtherValidationSchema = Yup.object().shape({
  productList: Yup.array()
    .of(
      Yup.object().shape({
        price: Yup.number().positive(),
        productId: Yup.number(),
      })
    )
    .test({
      name: "validate-serviceOther-products",
      message: null,
      test: (val, context) => validateServiceProducts(val),
    }),
});

const validatePetroleumProducts = (productsArray) => {
  const validProducts = productsArray?.filter(
    (x) =>
      x.productId &&
      x.unitOfMeasurement &&
      (x.unitOfMeasurement === UnitOfMeasurementsEnum.fillLiters ||
      x.unitOfMeasurement === UnitOfMeasurementsEnum.fillGallons
        ? true
        : x.quantity > 0)
  );

  if (validProducts?.length === 0)
    return new Yup.ValidationError(
      "Fill at least one product",
      null,
      "products"
    );

  return true;
};

const validatePropaneProducts = (productsArray) => {
  const validProducts = productsArray?.filter(
    (x) => x.currentPercentage && x.productId && x.tankSize
  );
    

  if (validProducts?.length === 0)
    return new Yup.ValidationError(
      "Fill at least one product",
      null,
      "products"
    );

  return true;
};

const validateServiceProducts = (productsArray) => {
  const validProducts = productsArray?.filter(
    (x) => x.price && x.productId
    
  );
  
  if (validProducts?.length === 0)
    return new Yup.ValidationError(
      "Fill at least one product",
      null,
      "products"
    );

    return true;
};

const CompleteWorkOrderModal = ({
  onSave = () => {},
  workOrder,
  ...otherProps
}) => {
  const [products, setProducts] = useState([]);

  const { closeModal } = useContext(ModalContext);
  const { setLoading } = useContext(LoadingContext);

  const {isPropaneBranch, isPetroleumBranch} = useBranchInfo();

  
  useEffect(() => {
    
    getProductsAsync();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getProductsAsync = async () => {
    setLoading(true);
    const response = await Services.api.products.all();
    setLoading(false);

    if (!response.ok)
      return Services.utility.toast(
        "Could not download the products",
        ThemeColorsEnum.error
      );

    setProducts(response.data);
  };

  const handleSubmit = async (values) => {
    const updatedWorkOrder = { ...workOrder, productList: values.productList };
    closeModal();
    onSave(updatedWorkOrder);
  };

  return (
    <AppForm
      initialValues={{
        productList: workOrder?.productList?.map((product) => ({
          ...product,
        })),
      }}
      onSubmit={handleSubmit}
      validationSchema={
        isPropaneBranch ? propaneOrderValidationSchema
        : isPetroleumBranch ? petroleumOrderValidationSchema
        : serviceOtherValidationSchema
      }
    >
      <Card.Body>
        <FormWorkOrderProducts
          availableProducts={products}
          completing
          directDrop={workOrder?.isDirectDrop}
          errorFieldName="products"
          label="Products"
          name="productList"
          required
        />
      </Card.Body>
      <Card.Footer>
        <Row className="mt-3">
          <Col className="text-end">
            <LoadingButton
              className="me-2"
              onClick={() => {
                closeModal();
              }}
              padding={10}
              color={ThemeColorsEnum.quaternary}
            >
              Cancel
            </LoadingButton>
            <SubmitButton color={ThemeColorsEnum.success}>
              Complete
            </SubmitButton>
          </Col>
        </Row>
      </Card.Footer>
    </AppForm>
  );
};

export default CompleteWorkOrderModal;
