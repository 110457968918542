import * as Yup from "yup";

import React, { useContext, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";

import { useNavigate } from "react-router-dom";
import Card from "../../components/cards/Card";
import AppForm from "../../components/forms/Form";
import FormTextField from "../../components/forms/FormTextField";
import FormTruckProducts from "../../components/forms/FormTruckProducts";
import SubmitButton from "../../components/forms/SubmitButton";
import { AppRoutes } from "../../constants/appRoutes";
import { ThemeColorsEnum } from "../../constants/enums";
import { LoadingContext } from "../../context/contextProvider";
import Services from "../../services/services";
import Page from "../Page";

const validationSchema = Yup.object().shape({
  name: Yup.string().min(1).required().label("Name"),
});

const TruckFormPage = ({ breadcrumbs, title, truck = {}, ...otherProps }) => {
  const [products, setProducts] = useState([]);

  const { busy, setBusy, setLoading } = useContext(LoadingContext);
  const navigate = useNavigate();

  useEffect(() => {
    initAsync();
    return () => {
      setProducts([]);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getProductsAsync = async () => {
    const response = await Services.api.products.all();

    if (!response.ok)
      return Services.utility.toast(
        "Could not download the products",
        ThemeColorsEnum.error
      );

    setProducts(response.data);
  };

  const handleSubmit = async (values) => {
    setBusy(true);
    const response = await Services.api.trucks.save({
      ...truck,
      ...values,
    });
    setBusy(false);

    if (!response.ok)
      return Services.utility.toast(
        "Could not save the truck",
        ThemeColorsEnum.error,
        response
      );

    navigate(AppRoutes.trucks);
    Services.utility.toast("Saved", ThemeColorsEnum.success);
  };

  const initAsync = async () => {
    setLoading(true);
    await getProductsAsync();
    setLoading(false);
  };

  return (
    <Page breadcrumbs={breadcrumbs} title={title}>
      <Card>
        <Card.Body>
          <AppForm
            initialValues={{
              make: truck?.make ?? "",
              model: truck?.model ?? "",
              name: truck?.name ?? "",
              notes: truck?.notes ?? "",
              truckProducts: truck?.truckProducts ?? [],
              year: truck?.year?.toString() ?? "",
            }}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
          >
            <Row>
              <Col>
                <FormTextField label="Name" name="name" required />
              </Col>
              <Col>
                <FormTextField label="Year" name="year" type="number" />
              </Col>
              <Col>
                <FormTextField label="Make" name="make" />
              </Col>
              <Col>
                <FormTextField label="Model" name="model" />
              </Col>
            </Row>

            <Row>
              <Col lg={4}>
                <FormTruckProducts
                  availableProducts={products}
                  errorFieldName="products"
                  label="Products"
                  name="truckProducts"
                />
              </Col>
            </Row>

            <Row>
              <Col lg={8}>
                <FormTextField
                  as="textarea"
                  className="custom-text-area"
                  label="Notes"
                  name="notes"
                />
              </Col>
            </Row>

            <div className="form-group mb-0 fixed-to-bottom">
              <Row>
                <Col lg={2} md={4} sm={6}>
                  <SubmitButton className="btn-block" loading={busy}>
                    Save
                  </SubmitButton>
                </Col>
              </Row>
            </div>
          </AppForm>
        </Card.Body>
      </Card>
    </Page>
  );
};

export default TruckFormPage;
