import React, { useContext, useEffect, useState, useRef } from "react";
import { Col, Row } from "react-bootstrap";
import {
  OrderStatusIdEnum,
  StatusEnum,
  ThemeColorsEnum,
} from "../../constants/enums";
import {
  AuthContext,
  LoadingContext,
  ModalContext,
} from "../../context/contextProvider";
import { Order, Search } from "../../models/searchFilterRequest";

import DashboardIcon from "@mui/icons-material/Dashboard";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import PieChartIcon from "@mui/icons-material/PieChart";
import { Alert } from "@mui/material";
import dayjs from "dayjs";
import { CSVLink } from "react-csv";
import { Navigate } from "react-router-dom";
import Button from "../../components/buttons/Button";
import Card from "../../components/cards/Card";
import EmptyDataBox from "../../components/dataTables/EmptyDataBox";
import NSDataTable from "../../components/dataTables/NSDataTable";
import NSDataTableReports from "../../components/dataTables/NSDataTableReports";
import DispatchReportFilterForm from "../../components/forms/custom/DispatchReportFilterForm";
import { AppRoutes } from "../../constants/appRoutes";
import Constants from "../../constants/constants";
import useCurrentUserInfo from "../../hooks/useCurrentUserInfo";
import useDispatchReportsDataTable from "../../hooks/useDispatchReportsDataTable";
import WorkOrderFilterRequest from "../../models/workOrderFilterRequest";
import Services from "../../services/services";
import Page from "../Page";
import WorkOrderDetails from "../workOrders/WorkOrderDetails";

const title = "Reports - Dispatches";
const breadcrumbs = [
  {
    IconComponent: DashboardIcon,
    title: "Dashhboard",
    to: AppRoutes.dashboard,
  },
  {
    IconComponent: PieChartIcon,
    title: "Dispatches Report",
  },
];

const DispatchesReportsPage = (props) => {
  // data-tables - start
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(Constants.defaults.rowsPerPage);
  const [paginationData, setPaginationData] = useState({
    from: null,
    pages: null,
    records: null,
    to: null,
  });
  const [searchValue, setSearchValue] = useState("");
  const dataTableInfo = useDispatchReportsDataTable();
  // data-tables - end
  const [orders, setOrders] = useState([]);
  const [filterDescription, setFilterDescription] = useState("");

  const [sortOptions, setSortOptions] = useState({
    column: dataTableInfo.defaultSortColumn(true),
    direction: dataTableInfo.defaultSortOrder(true),
  });

  const allSelected = useRef(false);

  const defaultReportFilter = new WorkOrderFilterRequest();
  defaultReportFilter.search = new Search();
  defaultReportFilter.start = 0;
  defaultReportFilter.length = Constants.defaults.rowsPerPage;
  defaultReportFilter.status = StatusEnum.completed;
  defaultReportFilter.orderStatusId = OrderStatusIdEnum.completed;
  defaultReportFilter.isDirectDrop = null;
  defaultReportFilter.isSchedule = false;
  defaultReportFilter.order[0].column = sortOptions.column;
  defaultReportFilter.order[0].dir = sortOptions.direction;
  // defaultReportFilter.completedOnFrom = dayjs().subtract(12, "month");
  defaultReportFilter.completedOnFrom = dayjs().startOf("year");
  defaultReportFilter.completedOnTo = dayjs();
  defaultReportFilter.customerId = null;

  const [filter, setFilter] = useState({ ...defaultReportFilter });

  const { setLoading } = useContext(LoadingContext);
  const { currentClient } = useContext(AuthContext);
  const { showModal, closeModal } = useContext(ModalContext);

  const userInfo = useCurrentUserInfo();

  useEffect(() => {
    if (allSelected.current) {
      getOrdersAsync((currentPage - 1) * pageSize, pageSize, searchValue, null, -1);
    }
    else {
      getOrdersAsync((currentPage - 1) * pageSize, pageSize, searchValue, null, pageSize);
    } 
  }, [currentClient, currentPage, pageSize, searchValue, sortOptions]);

  const getOrdersAsync = async (
    start = 0,
    take = Constants.defaults.rowsPerPage,
    searchText = null,
    filterArg = null,
    filterLength = Constants.defaults.rowsPerPage,
  ) => {
    const newFilter = filterArg ?? {
      ...filter,
      start: start,
      take: take,
      order: [new Order(sortOptions.column, sortOptions.direction)],
      search: new Search(searchText),
      length: filterLength,
    };
    setFilter(newFilter);

    const textFilter = [
      ...(!!newFilter.completedOnFrom
        ? [`From: ${Services.utility.formatISODate(newFilter.completedOnFrom)}`]
        : []),
      ...(!!newFilter.completedOnTo
        ? [`To: ${Services.utility.formatISODate(newFilter.completedOnTo)}`]
        : []),
      ...(!!newFilter.customerName
        ? !!newFilter.customerLocationName
          ? [
              `Customer: ${newFilter.customerName} (${newFilter.customerLocationName})`,
            ]
          : [`Customer: ${newFilter.customerName}`]
        : []),
      ...(!!newFilter.productName ? [`Product: ${newFilter.productName}`] : []),
      ...(!!newFilter.assignedToUserName
        ? [`Assigned to: ${newFilter.assignedToUserName}`]
        : []),
      ...(!!newFilter.completedByUserName
        ? [`Completed by: ${newFilter.completedByUserName}`]
        : []),
      ...(!!newFilter.workOrderTypeName
        ? [`Order type: ${newFilter.workOrderTypeName}`]
        : []),
    ];
    setFilterDescription(textFilter);

    setLoading(true);
    const response = await Services.api.workOrders.getWorkOrders(
      JSON.stringify(newFilter)
      // newFilter
    );
    setLoading(false);

    if (!response.ok)
      return Services.utility.toast(
        "Could not fetch the orders",
        ThemeColorsEnum.error
      );

    setOrders(response?.data?.data);
    setPaginationData({
      from: (currentPage - 1) * pageSize + 1,
      pages: response.data?.pagesTotal,
      records: response.data?.recordsTotal,
      to:
        currentPage * pageSize > response.data?.recordsTotal
          ? response.data?.recordsTotal
          : currentPage * pageSize,
    });
  };

  const handlePageChange = async (page) => {
    setCurrentPage(page);
  };

  const handleResetFilter = async (event) => {
    event.preventDefault();
    setCurrentPage(1);
    await getOrdersAsync(0, pageSize, searchValue, defaultReportFilter);
  };

  const handleRowClick = (event, row) => {
    event.stopPropagation();
    // opend the details modal
    showModal(
      `Dispatch Order #${row.id}`,
      <WorkOrderDetails workOrder={row} />,
      [
        {
          color: ThemeColorsEnum.quaternary,
          title: "Close",
        },
      ]
    );
  };

  const handleRowsPerPageChange = (newPerPage, currentPage) => {
    setCurrentPage(1);
    setPageSize(newPerPage);
  };

  const handleRunFilter = async (values) => {
    setCurrentPage(1);
    await getOrdersAsync(0, pageSize, searchValue, values);
  };

  const handleSearch = (searchValue) => {
    setCurrentPage(1);
    setSearchValue(searchValue);
  };

  const handleShowFilterForm = () => {
    showModal(
      "Filter",
      null,
      null,
      <DispatchReportFilterForm
        filter={filter}
        onCancel={closeModal}
        onSave={handleRunFilter}
      />,
      "lg"
    );
  };

  const handleSort = (column, direction) => {
    setCurrentPage(1);
    setSortOptions({ column: column, direction: direction });
  };

  if (!userInfo?.isAdmin)
    return (
      <Navigate
        to={{
          pathname: AppRoutes.forbidden,
        }}
      />
    );

    const toggleAllSelected = () => {
      allSelected.current = !allSelected.current;
    };

  const DataTableButtons = (
    <>
      <Button
        IconComponent={FilterAltIcon}
        onClick={handleShowFilterForm}
        title="Filter"
      />
      <a className="me-4" href="/#" onClick={handleResetFilter}>
        Reset
      </a>
      <CSVLink
        className="btn btn-secondary"
        data={orders}
        headers={[
          { label: "Completed by", key: "completedByName" },
          { label: "Customer", key: "customerDisplayName" },
          { label: "Products", key: "productsListFormatted" },
          { label: "Status", key: "orderStatus" },
          { label: "Completed on", key: "completedDateFormatted" },
        ]}
        filename={`dispatches_${Services.utility.formatISODate(
          filter.completedOnFrom
        )}_to_${Services.utility.formatISODate(filter.completedOnTo)}.csv`}
        target="_blank"
      >
        Export to Excel
      </CSVLink>
    </>
  );

  return (
    <Page breadcrumbs={breadcrumbs} title={title}>
      <Row>
        <Col>
          <Card>
            <Card.Body>
              {!!filterDescription && (
                <Row>
                  <Col>
                    <Alert className="mb-3" severity={ThemeColorsEnum.info}>
                      {filterDescription.join(", ")}
                    </Alert>
                  </Col>
                </Row>
              )}
              <div className="dataTables_wrapper dt-bootstrap5 no-footer">
                {/* **** BOOTSTRAP PAGINATION **** */}
                <NSDataTableReports
                  from={paginationData?.from}
                  lastPage={paginationData?.pages}
                  page={currentPage}
                  pageSize={pageSize}
                  total={paginationData?.records}
                  to={paginationData?.to}
                  onPageChange={handlePageChange}
                  onPageSizeChange={(newPerPage) =>
                    handleRowsPerPageChange(newPerPage, currentPage)
                  }
                  handleAll={()=>{toggleAllSelected();}}
                />
                {/* **** DATA TABLE **** */}
                <NSDataTable
                  buttons={DataTableButtons}
                  className="dataTable table-no-more"
                  // dense
                  columns={dataTableInfo.columns}
                  conditionalRowStyles={dataTableInfo.conditionalRowStyles}
                  data={orders}
                  emptyDataComponent={<EmptyDataBox />}
                  onRowClick={handleRowClick}
                  onSearch={handleSearch}
                  onSort={handleSort}
                  paginate
                  search
                  sortBy={sortOptions.column}
                  sortDirection={sortOptions.direction}
                />
                {/* **** BOOTSTRAP PAGINATION **** */}
                <NSDataTableReports
                  from={paginationData?.from}
                  lastPage={paginationData?.pages}
                  page={currentPage}
                  pageSize={pageSize}
                  showRecordsInfo
                  total={paginationData?.records}
                  to={paginationData?.to}
                  onPageChange={handlePageChange}
                  onPageSizeChange={(newPerPage) =>
                    handleRowsPerPageChange(newPerPage, currentPage)
                  }
                  handleAll={()=>{toggleAllSelected();}}
                />
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Page>
  );
};

export default DispatchesReportsPage;
