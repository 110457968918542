import React, { useContext } from "react";

import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import BuildIcon from "@mui/icons-material/Build";
import BusinessIcon from "@mui/icons-material/Business";
import DashboardIcon from "@mui/icons-material/Dashboard";
import EventRepeatIcon from "@mui/icons-material/EventRepeat";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import HolidayVillageIcon from "@mui/icons-material/HolidayVillage";
import LocalGasStationIcon from "@mui/icons-material/LocalGasStation";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import OilBarrelIcon from "@mui/icons-material/OilBarrel";
import PeopleIcon from "@mui/icons-material/People";
import PieChartIcon from "@mui/icons-material/PieChart";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import PropaneIcon from "@mui/icons-material/Propane";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import SettingsRemoteIcon from "@mui/icons-material/SettingsRemote";
import { useLocation } from "react-router-dom";
import { AppRoutes } from "../../constants/appRoutes";
import { AuthContext } from "../../context/contextProvider";
import useCurrentUserInfo from "../../hooks/useCurrentUserInfo";
import NavDropdown from "./NavDropdown";
import NavLink from "./NavLink";

const SidebarNav = ({ visible = true }) => {
  const { branchModules, currentClient } = useContext(AuthContext);

  const { pathname } = useLocation();
  const userInfo = useCurrentUserInfo();

  if (!visible) return null;

  return (
    <aside id="sidebar-left" className="sidebar-left">
      <div className="sidebar-header">
        <div className="sidebar-title text-muted text-uppercase">Menu</div>
        <div
          className="sidebar-toggle hidden-xs hidden-mobile"
          data-toggle-class="sidebar-left-collapsed"
          data-target="html"
          data-fire-event="sidebar-left-toggle"
        >
          <i className="fa fa-bars" aria-label="Toggle sidebar"></i>
        </div>
      </div>
      <div className="nano">
        <div className="nano-content">
          <nav id="menu" className="nav-main" role="navigation">
            <ul className="nav nav-main">
              <NavLink
                active={pathname === AppRoutes.dashboard}
                // icon="bx bxs-dashboard"
                IconComponent={DashboardIcon}
                title="Dashboard"
                to={AppRoutes.dashboard}
              />
              {/* Delivery Orders */}
              <NavDropdown
                active={pathname.startsWith(AppRoutes.deliveryOrders)}
                IconComponent={LocalGasStationIcon}
                title="Delivery Orders"
                visible={!!branchModules?.workOrders}
              >
                <NavLink
                  active={pathname === AppRoutes.deliveryOrders}
                  title="Active"
                  to={AppRoutes.deliveryOrders}
                />
                <NavLink
                  active={pathname === AppRoutes.deliveryOrders_completed}
                  title="Completed"
                  to={AppRoutes.deliveryOrders_completed}
                />
                <NavLink
                  active={pathname === AppRoutes.deliveryOrders_create}
                  title="Add New"
                  to={AppRoutes.deliveryOrders_create}
                />
                <NavLink
                  active={pathname === AppRoutes.deliveryOrders_map}
                  title="Map"
                  to={AppRoutes.deliveryOrders_map}
                />
                {/* Schedules */}
                {/* {!!currentClient.hasSchedules && ( */}
                <NavLink
                  active={pathname === AppRoutes.scheduledOrders}
                  IconComponent={EventRepeatIcon}
                  title="Schedules"
                  to={AppRoutes.scheduledOrders}
                />
                {/* )} */}
              </NavDropdown>
              {/* Direct Drops */}
              <NavDropdown
                active={pathname.startsWith(AppRoutes.directDrops)}
                IconComponent={OilBarrelIcon}
                title="Direct Drops"
                visible={!!branchModules?.directDrops}
              >
                <NavLink
                  active={pathname === AppRoutes.directDrops}
                  title="Active"
                  to={AppRoutes.directDrops}
                />
                <NavLink
                  active={pathname === AppRoutes.directDrops_completed}
                  title="Completed"
                  to={AppRoutes.directDrops_completed}
                />
                <NavLink
                  active={pathname === AppRoutes.directDrops_create}
                  title="Add New"
                  to={AppRoutes.directDrops_create}
                />
                <NavLink
                  active={pathname === AppRoutes.directDrops_map}
                  title="Map"
                  to={AppRoutes.directDrops_map}
                />
              </NavDropdown>
              {/* Service Orders */}
              <NavDropdown
                active={pathname.startsWith(AppRoutes.serviceOrders)}
                IconComponent={BuildIcon}
                title="Service Orders"
                visible={!!branchModules?.serviceOrders}
              >
                <NavLink
                  active={pathname === AppRoutes.serviceOrders}
                  title="Active"
                  to={AppRoutes.serviceOrders}
                />
                <NavLink
                  active={pathname === AppRoutes.serviceOrders_completed}
                  title="Completed"
                  to={AppRoutes.serviceOrders_completed}
                />
                <NavLink
                  active={pathname === AppRoutes.serviceOrders_create}
                  title="Add New"
                  to={AppRoutes.serviceOrders_create}
                />
                <NavLink
                  active={pathname === AppRoutes.serviceOrders_map}
                  title="Map"
                  to={AppRoutes.serviceOrders_map}
                />
                {/* Categories */}
                <NavDropdown
                  active={pathname.startsWith(AppRoutes.serviceOrderCategories)}
                  IconComponent={FormatListBulletedIcon}
                  title="Categories"
                >
                  <NavLink
                    active={pathname === AppRoutes.serviceOrderCategories}
                    title="All"
                    to={AppRoutes.serviceOrderCategories}
                  />
                  <NavLink
                    active={
                      pathname === AppRoutes.serviceOrderCategories_create
                    }
                    title="Add New"
                    to={AppRoutes.serviceOrderCategories_create}
                  />
                </NavDropdown>
              </NavDropdown>
              {/* Products */}
              <NavDropdown
                active={pathname.startsWith(AppRoutes.products)}
                IconComponent={PropaneIcon}
                title="Products"
              >
                <NavLink
                  active={pathname === AppRoutes.products}
                  title="All"
                  to={AppRoutes.products}
                />
                <NavLink
                  active={pathname === AppRoutes.products_create}
                  title="Add New"
                  to={AppRoutes.products_create}
                />
              </NavDropdown>
              {/* Customers */}
              <NavDropdown
                active={pathname.startsWith(AppRoutes.customers)}
                IconComponent={HolidayVillageIcon}
                title="Customers"
              >
                <NavLink
                  active={pathname === AppRoutes.customers}
                  title="All"
                  to={AppRoutes.customers}
                />
                <NavLink
                  active={pathname === AppRoutes.customers_create}
                  title="Add New"
                  to={AppRoutes.customers_create}
                  visible={userInfo?.canCreateCustomers}
                />
                <NavLink
                  active={pathname === AppRoutes.customers_map}
                  title="Map"
                  to={AppRoutes.customers_map}
                />
                <NavLink
                  active={pathname === AppRoutes.customers_import}
                  title="Import Tool"
                  to={AppRoutes.customers_import}
                  visible={
                    userInfo.isSuperAdmin
                    // && !Services.utility.isProduction()
                  }
                />
              </NavDropdown>
              {/* Trucks */}
              <NavDropdown
                active={pathname.startsWith(AppRoutes.trucks)}
                IconComponent={LocalShippingIcon}
                title="Trucks"
              >
                <NavLink
                  active={pathname === AppRoutes.trucks}
                  title="All"
                  to={AppRoutes.trucks}
                />
                <NavLink
                  active={pathname === AppRoutes.trucks_create}
                  title="Add New"
                  to={AppRoutes.trucks_create}
                />
              </NavDropdown>
              {/* Work Alone */}
              <NavDropdown
                active={pathname.startsWith(AppRoutes.workAlone)}
                IconComponent={ReportProblemIcon}
                title="Work Alone"
                visible={!!branchModules?.workAlone}
              >
                <NavLink
                  active={pathname === AppRoutes.scheduledCheckIns}
                  title="Scheduled Check-ins"
                  to={AppRoutes.scheduledCheckIns}
                />
              </NavDropdown>
              {/* <NavLink
                badgeTitle="New"
                color={ThemeColorsEnum.warning}
                IconComponent={OpenInBrowserIcon}
                title="Older Version v2"
                href={`${
                  process.env.REACT_APP_V2
                }?access_token=${Services.localStorage.getProperty(
                  LocalStorageKeys.accessToken
                )}`}
              /> */}
              <hr className="separator" />
            </ul>
          </nav>
          {/* Admin Only */}
          {userInfo.isAdmin && (
            <>
              <div className="sidebar-widget">
                <div className="widget-header">
                  <h6 className="text-muted text-uppercase">Admin Only</h6>
                </div>
              </div>
              <div className="widget-content">
                <nav id="menu" className="nav-main" role="navigation">
                  <ul className="nav nav-main">
                    {/* Users */}
                    <NavDropdown
                      active={pathname.startsWith(AppRoutes.users)}
                      IconComponent={PeopleIcon}
                      title="Users"
                    >
                      <NavLink
                        active={pathname === AppRoutes.users}
                        title="All"
                        to={AppRoutes.users}
                      />
                      <NavLink
                        active={pathname === AppRoutes.users_deleted}
                        title="Deleted"
                        to={AppRoutes.users_deleted}
                      />
                      <NavLink
                        active={pathname === AppRoutes.users_create}
                        title="Add New"
                        to={AppRoutes.users_create}
                      />
                      <NavLink
                        active={pathname === AppRoutes.users_map}
                        title="Map"
                        to={AppRoutes.users_map}
                      />
                    </NavDropdown>
                    {/* Tattles */}
                    <NavDropdown
                      active={pathname.startsWith(AppRoutes.tattles)}
                      // badgeTitle="New"
                      IconComponent={SettingsRemoteIcon}
                      title="Tattles"
                    >
                      <NavLink
                        active={pathname === AppRoutes.tattles}
                        title="All"
                        to={AppRoutes.tattles}
                      />
                      <NavLink
                        active={pathname === AppRoutes.tattles_create}
                        title="Add New"
                        to={AppRoutes.tattles_create}
                      />
                    </NavDropdown>

                    {/* Reports */}
                    <NavDropdown
                      active={pathname.startsWith(AppRoutes.reports)}
                      IconComponent={PieChartIcon}
                      title="Reports"
                    >
                      <NavLink
                        active={pathname === AppRoutes.reports_dispatches}
                        title="Completed Dispatches"
                        to={AppRoutes.reports_dispatches}
                      />
                      <NavLink
                        active={pathname === AppRoutes.reports_services}
                        title="Completed Services"
                        to={AppRoutes.reports_services}
                      />
                    </NavDropdown>

                    {/* Branches */}
                    <NavDropdown
                      active={pathname.startsWith(AppRoutes.branches)}
                      IconComponent={BusinessIcon}
                      title="Branches"
                    >
                      <NavLink
                        active={pathname === AppRoutes.branches}
                        title="All"
                        to={AppRoutes.branches}
                      />
                      <NavLink
                        active={pathname === AppRoutes.branches_create}
                        state={{
                          branch: userInfo.isSuperAdmin
                            ? null
                            : {
                                fee: currentClient?.fee,
                                name: currentClient?.name,
                                parentClientId:
                                  currentClient?.parentClientId ??
                                  currentClient?.id,
                              },
                        }}
                        title="Add New"
                        to={AppRoutes.branches_create}
                        visible={userInfo.isAdmin}
                      />
                    </NavDropdown>

                    {/* SuperAdmin */}
                    {/* Billing */}
                    {userInfo.isSuperAdmin && (
                      <NavDropdown
                        active={pathname.startsWith(AppRoutes.billing)}
                        IconComponent={PointOfSaleIcon}
                        title="Billing"
                      >
                        <NavLink
                          active={pathname === AppRoutes.billingClients}
                          title="Enrolled Clients"
                          to={AppRoutes.billingClients}
                        />
                        <NavLink
                          active={pathname === AppRoutes.billingUsers}
                          title="Billable Users"
                          to={AppRoutes.billingUsers}
                        />
                        <NavLink
                          active={pathname === AppRoutes.billingTrialUsers}
                          title="Trial Users"
                          to={AppRoutes.billingTrialUsers}
                        />
                      </NavDropdown>
                    )}

                    {/* Preferences */}
                    <NavDropdown
                      active={pathname.startsWith(AppRoutes.admin)}
                      // badgeTitle="New"
                      // color={ThemeColorsEnum.warning}
                      IconComponent={AdminPanelSettingsIcon}
                      title="Preferences"
                      visible={userInfo.isAdmin}
                    >
                      {userInfo.isSuperAdmin && (
                        <NavLink
                          active={pathname === AppRoutes.alerts}
                          title="Alerts"
                          to={AppRoutes.alerts}
                        />
                      )}
                      <NavLink
                        active={pathname === AppRoutes.modules}
                        title="Modules"
                        to={AppRoutes.modules}
                      />
                      <NavLink
                        active={pathname === AppRoutes.settings}
                        title="Settings"
                        to={AppRoutes.settings}
                      />
                    </NavDropdown>
                  </ul>
                </nav>
              </div>
            </>
          )}
        </div>
      </div>
    </aside>
  );
};

export default SidebarNav;
