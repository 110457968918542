import { Col, Row } from "react-bootstrap";
import React, { useEffect, useState } from "react";

import AddIcon from "@mui/icons-material/Add";
import AppErrorMessage from "./ErrorMessage";
import AppFormLabel from "./FormLabel";
import CircleButton from "../buttons/CircleButton";
import FormFieldContainer from "./FormFieldContainer";
import { Link } from "react-router-dom";
import { ThemeColorsEnum } from "../../constants/enums";
import TruckProduct from "./TruckProduct";
import { useFormikContext } from "formik";

const FormTruckProducts = ({
  availableProducts,
  errorFieldName,
  label,
  LabelComponent,
  name,
  onSelectProduct = () => {},
  required,
  visible = true,
  ...otherProps
}) => {
  const formik = useFormikContext();
  const { errors, handleBlur, setFieldValue, touched, values } = formik;

  const [productsList, setProductsList] = useState(values[name]);

  useEffect(() => {
    setProductsList(values[name]);
  }, [values[name]]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleAddProduct = () => {
    setProductsList([
      ...productsList,
      {
        productId: 0,
        totalLoad: null,
        currentLoad: null,
      },
    ]);
  };

  const handleRemoveLastProduct = () => {
    const removed = productsList?.pop();
    const updatedProductList = productsList.filter((x) => x !== removed);
    setProductsList(updatedProductList);
    setFieldValue(name, updatedProductList);
  };

  const handleSelectProduct = (product, index) => {
    setFieldValue(`${name}[${index}]`, product);
    onSelectProduct(product, formik);
  };

  if (!visible) return null;

  return (
    <FormFieldContainer>
      <Row>
        <Col lg={10}>
          <AppFormLabel label={label} required={required} visible={!!label} />
          {LabelComponent}
        </Col>
        <Col lg={2} className="text-right">
          {/* <Link className="text-default" to="#">
        <i className="fa fa-plus-circle"></i>
      </Link> */}
          <CircleButton
            color={ThemeColorsEnum.quaternary}
            size={25}
            // style={{ backgroundColor: "#eaeaea" }}
            IconComponent={() => (
              <AddIcon className="cur-pointer" style={{ fontSize: 20 }} />
            )}
            onClick={handleAddProduct}
          />
        </Col>
      </Row>
      {productsList?.map((truckProduct, index) => (
        <TruckProduct
          className="mb-1"
          key={index}
          name={`${name}[${index}]`}
          onBlur={() => handleBlur(name)}
          onSelected={(selectedProduct) =>
            handleSelectProduct(selectedProduct, index)
          }
          products={availableProducts}
          truckProduct={truckProduct}
        />
      ))}
      <Row>
        <Col sm={8}>
          <AppErrorMessage
            error={errors[errorFieldName]}
            visible={!!touched[name] && !!errors[errorFieldName]}
          />
        </Col>
        {productsList?.length > 0 && (
          <Col className="text-right" sm={4}>
            <Link onClick={handleRemoveLastProduct} to="#">
              Remove
            </Link>
          </Col>
        )}
      </Row>
    </FormFieldContainer>
  );
};

export default FormTruckProducts;
