import React, { useContext, useEffect } from "react";
import { Navigate, Routes } from "react-router-dom";

import { Route } from "react-router";
import { AppRoutes } from "../../constants/appRoutes";
import { StatusEnum } from "../../constants/enums";
import { LocalStorageKeys } from "../../constants/storageKeys";
import { AuthContext } from "../../context/contextProvider";
import useAlerts from "../../hooks/useAlerts";
import useQuery from "../../hooks/useQuery";
import ContactPage from "../../pages/ContactPage";
import DashboardPage from "../../pages/DashboardPage";
import TestPage from "../../pages/TestPage";
import UserGuidesPage from "../../pages/UserGuidesPage";
import ModulesPage from "../../pages/admin/ModulesPage";
import SettingsPage from "../../pages/admin/SettingsPage";
import AlertCreatePage from "../../pages/admin/alerts/AlertCreatePage";
import AlertEditPage from "../../pages/admin/alerts/AlertEditPage";
import AlertsPage from "../../pages/admin/alerts/AlertsPage";
import ForgotPasswordPage from "../../pages/auth/ForgotPasswordPage";
import LoginPage from "../../pages/auth/LoginPage";
import ResetPasswordPage from "../../pages/auth/ResetPasswordPage";
import BranchUsersDetailsPage from "../../pages/billing/BranchUsersDetailsPage";
import ClientBranchesPage from "../../pages/billing/ClientBranchesPage";
import EnrolledClientsPage from "../../pages/billing/EnrolledClientsPage";
import EnrolledUsersPage from "../../pages/billing/EnrolledUsersPage";
import TrialUsersPage from "../../pages/billing/TrialUsersPage";
import BranchCreatePage from "../../pages/branches/BranchCreatePage";
import BranchEditPage from "../../pages/branches/BranchEditPage";
import BranchesPage from "../../pages/branches/BranchesPage";
import CustomerCreatePage from "../../pages/customers/CustomerCreatePage";
import CustomerEditPage from "../../pages/customers/CustomerEditPage";
import CustomerLocationAddPage from "../../pages/customers/CustomerLocationAddPage";
import CustomerLocationEditPage from "../../pages/customers/CustomerLocationEditPage";
import CustomerLocationsPage from "../../pages/customers/CustomerLocationsPage";
import CustomersImportPage from "../../pages/customers/CustomersImportPage";
import CustomersMapPage from "../../pages/customers/CustomersMapPage";
import CustomersPage from "../../pages/customers/CustomersPage";
import DeliveryOrderCreatePage from "../../pages/deliveryOrders/DeliveryOrderCreatePage";
import DeliveryOrderEditPage from "../../pages/deliveryOrders/DeliveryOrderEditPage";
import DeliveryOrdersActivePage from "../../pages/deliveryOrders/DeliveryOrdersActivePage";
import DeliveryOrdersCompletedPage from "../../pages/deliveryOrders/DeliveryOrdersCompletedPage";
import DeliveryOrdersMapPage from "../../pages/deliveryOrders/DeliveryOrdersMapPage";
import DirectDropCreatePage from "../../pages/directDrops/DirectDropCreatePage";
import DirectDropEditPage from "../../pages/directDrops/DirectDropEditPage";
import DirectDropsActivePage from "../../pages/directDrops/DirectDropsActivePage";
import DirectDropsCompletedPage from "../../pages/directDrops/DirectDropsCompletedPage";
import DirectDropsMapPage from "../../pages/directDrops/DirectDropsMapPage";
import ForbiddenPage from "../../pages/errors/ForbiddenPage";
import NotFoundPage from "../../pages/errors/NotFoundPage";
import ProductCreatePage from "../../pages/products/ProductCreatePage";
import ProductEditPage from "../../pages/products/ProductEditPage";
import ProductsPage from "../../pages/products/ProductsPage";
import DispatchesReportsPage from "../../pages/reports/DispatchesReportsPage";
import ServicesReportsPage from "../../pages/reports/ServicesReportsPage";
import ScheduledOrderCreatePage from "../../pages/schedules/ScheduledOrderCreatePage";
import ScheduledOrderEditPage from "../../pages/schedules/ScheduledOrderEditPage";
import ScheduledOrdersPage from "../../pages/schedules/ScheduledOrdersPage";
import ServiceOrderCategoriesPage from "../../pages/serviceOrderCategories/ServiceOrderCategoriesPage";
import ServiceOrderCategoryCreatePage from "../../pages/serviceOrderCategories/ServiceOrderCategoryCreatePage";
import ServiceOrderCategoryEditPage from "../../pages/serviceOrderCategories/ServiceOrderCategoryEditPage";
import ServiceOrderCreatePage from "../../pages/serviceOrders/ServiceOrderCreatePage";
import ServiceOrderEditPage from "../../pages/serviceOrders/ServiceOrderEditPage";
import ServiceOrdersActivePage from "../../pages/serviceOrders/ServiceOrdersActivePage";
import ServiceOrdersCompletedPage from "../../pages/serviceOrders/ServiceOrdersCompletedPage";
import ServiceOrdersMapPage from "../../pages/serviceOrders/ServiceOrdersMapPage";
import TattleCreatePage from "../../pages/tattles/TattleCreatePage";
import TattleEditPage from "../../pages/tattles/TattleEditPage";
import TattlesPage from "../../pages/tattles/TattlesPage";
import TruckCreatePage from "../../pages/trucks/TruckCreatePage";
import TruckEditPage from "../../pages/trucks/TruckEditPage";
import TrucksPage from "../../pages/trucks/TrucksPage";
import UserCreatePage from "../../pages/users/UserCreatePage";
import UserEditPage from "../../pages/users/UserEditPage";
import UsersMapPage from "../../pages/users/UsersMapPage";
import UsersPage from "../../pages/users/UsersPage";
import ScheduledCheckInsPage from "../../pages/workAlone/ScheduledCheckInsPage";
import Services from "../../services/services";
import NavigateToLogin from "./NavigateToLogin";

const NavigationContainer = (props) => {
  const {
    currentUser,
    setCurrentUser,
    currentClient,
    setCurrentClient,
    setBranchModules,
    setBranchSettings,
    setUserSettings,
  } = useContext(AuthContext);

  const query = useQuery();
  useAlerts();

  useEffect(() => {
    const token = query?.get("access_token");
    if (token) {
      processAccessTokenAsync(token);
    }
  }, [query]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (currentUser) initAsync();
  }, [currentUser, currentClient]); // eslint-disable-line react-hooks/exhaustive-deps

  const initAsync = async () => {
    // setLoading(true);
    const response = await Services.api.utility.initialize();
    // setLoading(false);

    setBranchModules(response.data?.branchModules);
    setBranchSettings(response.data?.branchSettings);
    setUserSettings(response.data?.userSettings);
  };

  const processAccessTokenAsync = async (accessToken) => {
    Services.localStorage.setProperty(
      LocalStorageKeys.accessToken,
      accessToken
    );
    const response = await Services.api.account.me();

    if (response.ok) {
      setCurrentUser(response.data);
      setCurrentClient(response.data?.primaryClient);
    }
  };

  return (
    // <Router forceRefresh={false}> // moved to App.js
    <Routes>
      <Route
        exact
        path={AppRoutes.dashboard}
        element={currentUser ? <DashboardPage /> : <NavigateToLogin />}
      />
      {/* Admin */}
      <Route
        exact
        path={AppRoutes.modules}
        element={currentUser ? <ModulesPage /> : <NavigateToLogin />}
      />
      <Route
        exact
        path={AppRoutes.settings}
        element={currentUser ? <SettingsPage /> : <NavigateToLogin />}
      />
      <Route
        exact
        path={AppRoutes.alerts}
        element={currentUser ? <AlertsPage /> : <NavigateToLogin />}
      />
      <Route
        exact
        path={AppRoutes.alerts_create}
        element={currentUser ? <AlertCreatePage /> : <NavigateToLogin />}
      />
      <Route
        exact
        path={AppRoutes.alerts_edit}
        element={currentUser ? <AlertEditPage /> : <NavigateToLogin />}
      />

      {/* Billing */}
      <Route
        exact
        path={AppRoutes.billingClients}
        element={currentUser ? <EnrolledClientsPage /> : <NavigateToLogin />}
      />
      <Route
        exact
        path={AppRoutes.billingClientBranches}
        element={currentUser ? <ClientBranchesPage /> : <NavigateToLogin />}
      />
      <Route
        exact
        path={AppRoutes.billingBranchDetails}
        element={currentUser ? <BranchUsersDetailsPage /> : <NavigateToLogin />}
      />
      <Route
        exact
        path={AppRoutes.billingUsers}
        element={currentUser ? <EnrolledUsersPage /> : <NavigateToLogin />}
      />
      <Route
        exact
        path={AppRoutes.billingTrialUsers}
        element={currentUser ? <TrialUsersPage /> : <NavigateToLogin />}
      />

      {/* Branches */}
      <Route
        exact
        path={AppRoutes.branches}
        element={currentUser ? <BranchesPage /> : <NavigateToLogin />}
      />
      <Route
        exact
        path={AppRoutes.branches_create}
        element={currentUser ? <BranchCreatePage /> : <NavigateToLogin />}
      />
      <Route
        exact
        path={AppRoutes.branches_edit}
        element={currentUser ? <BranchEditPage /> : <NavigateToLogin />}
      />

      {/* Contact */}
      <Route
        exact
        path={AppRoutes.contact}
        element={currentUser ? <ContactPage /> : <NavigateToLogin />}
      />

      {/* Customers */}
      <Route
        exact
        path={AppRoutes.customers}
        element={currentUser ? <CustomersPage /> : <NavigateToLogin />}
      />
      <Route
        exact
        path={AppRoutes.customers_create}
        element={currentUser ? <CustomerCreatePage /> : <NavigateToLogin />}
      />
      <Route
        exact
        path={AppRoutes.customers_edit}
        element={currentUser ? <CustomerEditPage /> : <NavigateToLogin />}
      />
      <Route
        exact
        path={AppRoutes.customers_import}
        element={currentUser ? <CustomersImportPage /> : <NavigateToLogin />}
      />
      <Route
        exact
        path={AppRoutes.customers_map}
        element={currentUser ? <CustomersMapPage /> : <NavigateToLogin />}
      />
      <Route
        exact
        path={AppRoutes.customerLocations}
        element={currentUser ? <CustomerLocationsPage /> : <NavigateToLogin />}
      />
      <Route
        exact
        path={AppRoutes.customerLocations_edit}
        element={
          currentUser ? <CustomerLocationEditPage /> : <NavigateToLogin />
        }
      />
      <Route
        exact
        path={AppRoutes.customerLocations_add}
        element={
          currentUser ? <CustomerLocationAddPage /> : <NavigateToLogin />
        }
      />
      {/* Delivery orders */}
      <Route
        exact
        path={AppRoutes.deliveryOrders}
        element={
          currentUser ? <DeliveryOrdersActivePage /> : <NavigateToLogin />
        }
      />
      <Route
        exact
        path={AppRoutes.deliveryOrders_completed}
        element={
          currentUser ? <DeliveryOrdersCompletedPage /> : <NavigateToLogin />
        }
      />
      <Route
        exact
        path={AppRoutes.deliveryOrders_create}
        element={
          currentUser ? <DeliveryOrderCreatePage /> : <NavigateToLogin />
        }
      />
      <Route
        exact
        path={AppRoutes.deliveryOrders_edit}
        element={currentUser ? <DeliveryOrderEditPage /> : <NavigateToLogin />}
      />
      <Route
        exact
        path={AppRoutes.deliveryOrders_map}
        element={currentUser ? <DeliveryOrdersMapPage /> : <NavigateToLogin />}
      />
      {/* Direct drops */}
      <Route
        exact
        path={AppRoutes.directDrops}
        element={currentUser ? <DirectDropsActivePage /> : <NavigateToLogin />}
      />
      <Route
        exact
        path={AppRoutes.directDrops_completed}
        element={
          currentUser ? <DirectDropsCompletedPage /> : <NavigateToLogin />
        }
      />
      <Route
        exact
        path={AppRoutes.directDrops_create}
        element={currentUser ? <DirectDropCreatePage /> : <NavigateToLogin />}
      />
      <Route
        exact
        path={AppRoutes.directDrops_edit}
        element={currentUser ? <DirectDropEditPage /> : <NavigateToLogin />}
      />
      <Route
        exact
        path={AppRoutes.directDrops_map}
        element={currentUser ? <DirectDropsMapPage /> : <NavigateToLogin />}
      />
      {/* Login */}
      <Route
        exact
        path={AppRoutes.login}
        element={
          currentUser ? <Navigate to={AppRoutes.dashboard} /> : <LoginPage />
        }
      />
      {/* Password Reset */}
      <Route
        exact
        path={AppRoutes.forgotPassword}
        element={
          currentUser ? (
            <Navigate to={AppRoutes.dashboard} />
          ) : (
            <ForgotPasswordPage />
          )
        }
      />
      <Route
        exact
        path={AppRoutes.resetPassword}
        element={
          currentUser ? (
            <Navigate to={AppRoutes.dashboard} />
          ) : (
            <ResetPasswordPage />
          )
        }
      />
      {/* Products */}
      <Route
        exact
        path={AppRoutes.products}
        element={currentUser ? <ProductsPage /> : <NavigateToLogin />}
      />
      <Route
        exact
        path={AppRoutes.products_create}
        element={currentUser ? <ProductCreatePage /> : <NavigateToLogin />}
      />
      <Route
        exact
        path={AppRoutes.products_edit}
        element={currentUser ? <ProductEditPage /> : <NavigateToLogin />}
      />

      {/* Reports */}
      <Route
        exact
        path={AppRoutes.reports_dispatches}
        element={currentUser ? <DispatchesReportsPage /> : <NavigateToLogin />}
      />
      <Route
        exact
        path={AppRoutes.reports_services}
        element={currentUser ? <ServicesReportsPage /> : <NavigateToLogin />}
      />

      {/* Scheduled orders */}
      <Route
        exact
        path={AppRoutes.scheduledOrders}
        element={currentUser ? <ScheduledOrdersPage /> : <NavigateToLogin />}
      />
      <Route
        exact
        path={AppRoutes.scheduledOrders_create}
        element={
          currentUser ? <ScheduledOrderCreatePage /> : <NavigateToLogin />
        }
      />
      <Route
        exact
        path={AppRoutes.scheduledOrders_edit}
        element={currentUser ? <ScheduledOrderEditPage /> : <NavigateToLogin />}
      />

      {/* Service orders */}
      <Route
        exact
        path={AppRoutes.serviceOrders}
        element={
          currentUser ? <ServiceOrdersActivePage /> : <NavigateToLogin />
        }
      />
      <Route
        exact
        path={AppRoutes.serviceOrders_completed}
        element={
          currentUser ? <ServiceOrdersCompletedPage /> : <NavigateToLogin />
        }
      />
      <Route
        exact
        path={AppRoutes.serviceOrders_create}
        element={currentUser ? <ServiceOrderCreatePage /> : <NavigateToLogin />}
      />
      <Route
        exact
        path={AppRoutes.serviceOrders_edit}
        element={currentUser ? <ServiceOrderEditPage /> : <NavigateToLogin />}
      />
      <Route
        exact
        path={AppRoutes.serviceOrders_map}
        element={currentUser ? <ServiceOrdersMapPage /> : <NavigateToLogin />}
      />

      {/* Service order categories */}
      <Route
        exact
        path={AppRoutes.serviceOrderCategories}
        element={
          currentUser ? <ServiceOrderCategoriesPage /> : <NavigateToLogin />
        }
      />
      <Route
        exact
        path={AppRoutes.serviceOrderCategories_create}
        element={
          currentUser ? <ServiceOrderCategoryCreatePage /> : <NavigateToLogin />
        }
      />
      <Route
        exact
        path={AppRoutes.serviceOrderCategories_edit}
        element={
          currentUser ? <ServiceOrderCategoryEditPage /> : <NavigateToLogin />
        }
      />

      {/* Tattles */}
      <Route
        exact
        path={AppRoutes.tattles}
        element={currentUser ? <TattlesPage /> : <NavigateToLogin />}
      />
      <Route
        exact
        path={AppRoutes.tattles_create}
        element={currentUser ? <TattleCreatePage /> : <NavigateToLogin />}
      />
      <Route
        exact
        path={AppRoutes.tattles_edit}
        element={currentUser ? <TattleEditPage /> : <NavigateToLogin />}
      />

      {/* Trucks */}
      <Route
        exact
        path={AppRoutes.trucks}
        element={currentUser ? <TrucksPage /> : <NavigateToLogin />}
      />
      <Route
        exact
        path={AppRoutes.trucks_create}
        element={currentUser ? <TruckCreatePage /> : <NavigateToLogin />}
      />
      <Route
        exact
        path={AppRoutes.trucks_edit}
        element={currentUser ? <TruckEditPage /> : <NavigateToLogin />}
      />

      {/* User Guides */}
      <Route
        exact
        path={AppRoutes.userGuides}
        element={currentUser ? <UserGuidesPage /> : <NavigateToLogin />}
      />

      {/* Users */}
      <Route
        exact
        path={AppRoutes.users}
        element={currentUser ? <UsersPage /> : <NavigateToLogin />}
      />
      <Route
        exact
        path={AppRoutes.users_create}
        element={currentUser ? <UserCreatePage /> : <NavigateToLogin />}
      />
      <Route
        exact
        path={AppRoutes.users_deleted}
        element={
          currentUser ? (
            <UsersPage status={StatusEnum.deleted} />
          ) : (
            <NavigateToLogin />
          )
        }
      />
      <Route
        exact
        path={AppRoutes.users_edit}
        element={currentUser ? <UserEditPage /> : <NavigateToLogin />}
      />
      <Route
        exact
        path={AppRoutes.users_map}
        element={currentUser ? <UsersMapPage /> : <NavigateToLogin />}
      />

      {/* WorkAlone */}
      <Route
        exact
        path={AppRoutes.scheduledCheckIns}
        element={currentUser ? <ScheduledCheckInsPage /> : <NavigateToLogin />}
      />

      <Route
        exact
        path={AppRoutes.test}
        element={currentUser ? <TestPage /> : <NavigateToLogin />}
      />

      <Route path={AppRoutes.forbidden} element={<ForbiddenPage />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
    // </Router>
  );
};

export default NavigationContainer;
