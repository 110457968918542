import { BranchColumnsEnum, ThemeColorsEnum } from "../constants/enums";
import {
  AuthContext,
  LoadingContext,
  ModalContext,
} from "../context/contextProvider";

import dayjs from "dayjs";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import DropdownSelect from "../components/forms/DropdownSelect";
import { AppRoutes } from "../constants/appRoutes";
import Services from "../services/services";

const useBranchesDataTable = (onDeleted = () => {}) => {
  const navigate = useNavigate();

  const { currentClient } = useContext(AuthContext);
  const { setLoading } = useContext(LoadingContext);
  const { showConfirmation } = useContext(ModalContext);

  const handleDelete = async (branch) => {
    setLoading(true);
    const response = await Services.api.clients.delete(branch.id);
    setLoading(false);

    if (!response.ok)
      return Services.utility.toast(
        "Could not delete the branch",
        ThemeColorsEnum.error
      );

    Services.utility.toast("Tattle deleted", ThemeColorsEnum.success);
    onDeleted(branch);
  };

  const columns = [
    {
      header: {
        className: "col-menu",
        id: BranchColumnsEnum.actionButton,
        name: "id",
      },
      cell: (row) => (
        <DropdownSelect
          color={ThemeColorsEnum.quaternary}
          fixedTitle
          items={menuItems(row)}
          itemLabelProperty="title"
          itemValueProperty="title"
          size="sm"
          style={{ width: "auto" }}
          title={<i className="fa fa-bars" />}
          // title={<MenuIcon />}
        />
      ),
      dataTitle: "Menu",
      className: "nowrap",
    },
    {
      header: {
        id: BranchColumnsEnum.companyName,
        name: "name",
        text: "Company",
        sortable: true,
      },
      cell: (row) => row.name,
      dataTitle: "Company",
      className: "nowrap",
    },
    {
      header: {
        id: BranchColumnsEnum.branchName,
        name: "displayName",
        text: "Branch",
        sortable: true,
      },
      cell: (row) => row.displayName,
      dataTitle: "Branch",
      className: "nowrap",
    },
    {
      header: {
        id: BranchColumnsEnum.city,
        name: "city",
        text: "City",
        sortable: true,
      },
      cell: (row) => row.city,
      dataTitle: "City",
      className: "nowrap",
    },
    {
      header: {
        id: BranchColumnsEnum.service,
        name: "businessType",
        text: "Service",
        sortable: true,
      },
      cell: (row) => row.businessType,
      dataTitle: "Service",
      className: "nowrap",
    },
    {
      header: {
        id: BranchColumnsEnum.phone,
        name: "phone",
        text: "Phone",
        sortable: true,
      },
      cell: (row) => row.phone,
      dataTitle: "Phone",
      className: "nowrap",
    },
    {
      header: {
        id: BranchColumnsEnum.email,
        name: "email",
        text: "Email",
        sortable: true,
      },
      cell: (row) => row.email,
      dataTitle: "Email",
      className: "nowrap",
    },
    {
      header: {
        id: BranchColumnsEnum.expirationDate,
        name: "expirationDate",
        text: "Expiration",
        sortable: true,
      },
      cell: (row) => row.expiryDateFormatted,
      dataTitle: "Expiration",
      className: "nowrap",
    },
    {
      header: {
        id: BranchColumnsEnum.createdDate,
        name: "createdDate",
        text: "Added on",
        sortable: false,
      },
      cell: (row) => row.createdDateFormatted,
      dataTitle: "Added on",
      className: "nowrap",
    },
  ];

  const menuItems = (row) => [
    // Edit
    {
      onClick: () =>
        navigate(AppRoutes.branches_edit.replace(":id", row.id), {
          state: { branch: row },
        }),
      title: "Edit",
    },
    // Delete
    ...(row.guid !== currentClient?.guid
      ? [
          { type: "divider" },
          {
            className: "text-danger",
            onClick: () => {
              showConfirmation(
                "Confirmation",
                "Do you really want to delete this branch?",
                [
                  {
                    color: ThemeColorsEnum.quaternary,
                    title: "Cancel",
                  },
                  {
                    color: ThemeColorsEnum.danger,
                    onClick: () => handleDelete(row),
                    title: "Delete",
                  },
                ]
              );
            },
            title: "Delete",
          },
        ]
      : []),
  ];

  const conditionalRowStyles = [
    {
      when: (row) =>
        !!row.expiryDate && dayjs(row.expiryDate).diff(dayjs(), "day") <= 0,
      classNames: [ThemeColorsEnum.overdue],
    },
    {
      when: (row) =>
        !!row.expiryDate &&
        dayjs(row.expiryDate).diff(dayjs(), "day") > 0 &&
        !!row.expiryDate &&
        dayjs(row.expiryDate).diff(dayjs(), "day") < 7,
      classNames: [ThemeColorsEnum.due],
    },
  ];

  return { columns, conditionalRowStyles };
};

export default useBranchesDataTable;
