import { Col, Form, InputGroup, Row } from "react-bootstrap";
import React, { useEffect, useState, useContext } from "react";

import AppSelect from "./Select";
import Enumerable from "linq";
import { UnitOfMeasurementsEnum } from "../../constants/enums";
import useBranchInfo from "../../hooks/useBranchInfo";
import { useEffectAsync } from "../../hooks/useEffectAsync";
import settingsApiService from "../../services/api/settingsApiService";
import { AuthContext } from "../../context/contextProvider";

const WorkOrderProduct = ({
  className,
  completing,
  directDrop = false,
  onBlur = () => { },
  onSelected = () => { },
  products,
  workOrderProduct,
  ...otherProps
}) => {
  // state variables-------------
  const [tankSize, setTankSize] = useState(
    workOrderProduct?.tankSize?.toString() ?? ""
  );
  const [currentPercentage, setCurrentPercentage] = useState(
    workOrderProduct?.currentPercentage?.toString() ?? ""
  );
  const [quantity, setQuantity] = useState(
    workOrderProduct?.quantity?.toString() ?? ""
  );
  const [unitOfMeasurement, setUnitOfMeasurement] = useState(
    workOrderProduct?.unitOfMeasurement
  );
  const [productId, setProductId] = useState(workOrderProduct?.productId);
  const [price, setPrice] = useState(workOrderProduct?.price?.toString() ?? "");
  // state variables-------------

  const { isPetroleumBranch, isPropaneBranch } = useBranchInfo();


  const {
    branchSettings
  } = useContext(AuthContext);


  const enumAfterFilter = [];
  for (const [key, value] of Object.entries(UnitOfMeasurementsEnum)) {
    if (value === workOrderProduct?.unitOfMeasurement) {
      enumAfterFilter.push({ key, value });
    }
  }

  const unitOfMeasurements =
    completing ?
      (branchSettings.alwaysConvertToLiters ? [{key: "liters", value: UnitOfMeasurementsEnum.liters}]
        : enumAfterFilter)
      : Enumerable.from(UnitOfMeasurementsEnum).toArray();

  // const [ unitOfMeasurements, setUnitOfMeasurements ] = useState(completing
  //   ? (Enumerable.from(UnitOfMeasurementsEnum)
  //     .where((x) => x.value === workOrderProduct?.unitOfMeasurement)
  //     .toArray())
  //   : Enumerable.from(UnitOfMeasurementsEnum).toArray());

  const sortedProducts = Enumerable.from(products)
    .orderByDescending((x) => x.order !== null)
    .thenBy((x) => x.name)
    .thenBy((x) => x.order)
    .toArray();

  useEffect(() => {
    setTankSize(workOrderProduct?.tankSize?.toString() ?? "");
    setCurrentPercentage(workOrderProduct?.currentPercentage?.toString() ?? "");
    if (branchSettings.alwaysConvertToLiters && completing) {
      setUnitOfMeasurement(UnitOfMeasurementsEnum.liters);
      if (workOrderProduct.unitOfMeasurement == "G") {
          //convert to imperial gallon
          setQuantity(workOrderProduct?.quantity * 4.54609);
      }
    }
    else {
      setQuantity(workOrderProduct?.quantity?.toString() ?? "");
      setUnitOfMeasurement(workOrderProduct?.unitOfMeasurement);
    }
    setProductId(workOrderProduct?.productId);
    setPrice(workOrderProduct?.price?.toString() ?? "");
  }, [workOrderProduct]);

  useEffect(
    () => {
      const productData = {
        currentPercentage: currentPercentage,
        price: price,
        productId: productId,
        quantity: quantity,
        tankSize: tankSize,
        unitOfMeasurement: unitOfMeasurement,
      };
      onSelected(productData);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [quantity, unitOfMeasurement, productId, tankSize, currentPercentage, price]
  );

  return (
    // <InputGroup>
    <Row className={className}>
      {!!isPropaneBranch && (
        <Col className="pe-1">
          <Form.Control
            name="tankSize"
            onBlur={onBlur}
            onChange={(event) => setTankSize(event.target?.value)}
            placeholder="Tank size"
            type="number"
            value={tankSize}
          />
        </Col>
      )}
      {!!isPropaneBranch && (
        <Col className="px-1">
          <Form.Control
            name="currentPercentage"
            onBlur={onBlur}
            onChange={(event) => setCurrentPercentage(event.target?.value)}
            placeholder="%"
            type="number"
            value={currentPercentage}
          />
        </Col>
      )}
      {!!isPetroleumBranch && (
        <Col className="pe-1">
          <Form.Control
            name="quantity"
            onBlur={onBlur}
            onChange={(event) => setQuantity(event.target?.value)}
            placeholder={directDrop ? "Litres" : "Qty"}
            type="number"
            value={quantity}
          />
        </Col>
      )}
      {!!isPetroleumBranch && !directDrop && (
        <Col className="px-1">
          <AppSelect
            itemLabelProperty="value"
            items={unitOfMeasurements}
            itemValueProperty="value"
            name="unitOfMeasurement"
            onBlur={onBlur}
            onChange={(item) => setUnitOfMeasurement(item?.value)}
            placeholder="Unit"
            title="Unit"
            value={unitOfMeasurement}
          />
        </Col>
      )}
      <Col className="px-1">
        <AppSelect
          itemLabelProperty="shortName"
          items={sortedProducts}
          itemValueProperty="id"
          name="productId"
          onBlur={onBlur}
          onChange={(item) => {
            setProductId(item?.id);
          }}
          placeholder="Product"
          title="Product"
          value={productId}
        />
      </Col>
      <Col className="px-1">
        <Form.Control
          name="price"
          onBlur={onBlur}
          onChange={(event) => setPrice(event.target?.value)}
          placeholder="Price (¢)"
          value={price}
        />
      </Col>
    </Row>
    // </InputGroup>
  );
};

export default WorkOrderProduct;
