import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { RolesEnum, ThemeColorsEnum } from "../../constants/enums";
import { AuthContext, LoadingContext } from "../../context/contextProvider";

import Map from "../../components/maps/Map";
import AppMarker from "../../components/maps/Marker";
import { AppRoutes } from "../../constants/appRoutes";
import useCurrentPosition from "../../hooks/useCurrentPosition";
import useCurrentUserInfo from "../../hooks/useCurrentUserInfo";
import WorkOrderFilterRequest from "../../models/workOrderFilterRequest";
import Services from "../../services/services";
import Page from "../Page";

const WorkOrdersMapPage = ({
  breadcrumbs,
  directDrops,
  title,
  ...otherProps
}) => {
  const [customersLocations, setCustomersLocations] = useState([]);
  const [driversGeolocations, setDriversGeolocations] = useState([]);

  const { currentClient } = useContext(AuthContext);
  const { setLoading } = useContext(LoadingContext);

  const { position, positionLoaded } = useCurrentPosition();
  const userInfo = useCurrentUserInfo();

  useEffect(() => {
    initAsync();
  }, [currentClient]); // eslint-disable-line react-hooks/exhaustive-deps

  const getDriversGeolocationsAsync = async () => {
    const response = await Services.api.users.getLastKnownLocations([
      RolesEnum.driver,
    ]);

    if (!response.ok)
      return Services.utility.toast(
        "Could not fetch drivers locations",
        ThemeColorsEnum.error
      );
    setDriversGeolocations(response.data);
  };

  const getGeolocationsAsync = async () => {
    const response =
      await Services.api.workOrders.getActiveWorkOrdersGeolocations(
        directDrops
      );
    if (!response.ok)
      Services.utility.toast("Could not fetch map pins", ThemeColorsEnum.error);
    else setCustomersLocations(response.data);
  };

  const initAsync = async () => {
    setLoading(true);
    await getGeolocationsAsync();
    if (userInfo.isAdmin) await getDriversGeolocationsAsync();
    setLoading(false);
  };

  const InfoWindow = ({ customerLocation, ...otherProps }) => {
    // filter
    const filter = new WorkOrderFilterRequest();
    filter.customerId = customerLocation.customerId;
    filter.isDirectDrop = directDrops;

    return (
      <>
        <span className="info-window-text-title">
          {customerLocation.displayName}
        </span>
        <br />
        <Link to={AppRoutes.deliveryOrders} state={{ filter: filter }}>
          Click here to view the order(s)
        </Link>
      </>
    );
  };

  return (
    <Page breadcrumbs={breadcrumbs} title={title}>
      {!!positionLoaded && (
        <Map centerPosition={position} zoom={7}>
          {customersLocations?.map((customerLocation, index) => (
            <AppMarker
              clickable
              info={<InfoWindow customerLocation={customerLocation} />}
              key={customerLocation.id}
              order={{ rowClass: customerLocation.pinStatus }}
              position={{
                lat: customerLocation.latitude,
                lng: customerLocation.longitude,
              }}
            />
          ))}
          {driversGeolocations?.map((driverGeolocation, index) => (
            <AppMarker
              clickable
              icon={`${process.env.PUBLIC_URL}/img/ic_pin_truck.png`}
              info={
                <>
                  <span className="info-window-text-title">
                    {driverGeolocation.displayName}
                  </span>
                  <br />
                  <span className="info-window-text">
                    {driverGeolocation.createdDateFormatted}
                  </span>
                </>
              }
              key={driverGeolocation.id}
              position={{
                lat: driverGeolocation.geolocation?.location?.latitude,
                lng: driverGeolocation.geolocation?.location?.longitude,
              }}
            />
          ))}
        </Map>
      )}
    </Page>
  );
};

export default WorkOrdersMapPage;
