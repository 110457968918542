import Card from "./Card";
import React from "react";
import { ThemeColorsEnum } from "../../constants/enums";

const DashboardCard = ({
  className,
  color = ThemeColorsEnum.primary,
  footerText,
  icon,
  IconComponent,
  onClick,
  ordersCount,
  title,
}) => {
  return (
    <Card className={className} onClick={onClick}>
      <Card.Body>
        <div className="widget-summary">
          <div className="widget-summary-col widget-summary-col-icon">
            <div className={`summary-icon bg-${color}`}>
              {(icon && <i className={icon}></i>) ||
                (IconComponent && <IconComponent />)}
            </div>
          </div>
          <div className="widget-summary-col">
            <div className="summary">
              <h4 className="title">{title}</h4>
              <div className="info">
                <strong className="amount">{ordersCount}</strong>
              </div>
            </div>
            <div className="summary-footer">
              <span className={`text-${color}`}>{footerText}</span>
            </div>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};

export default DashboardCard;
