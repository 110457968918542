import React, { useContext, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import {
  CustomerColumnsEnum,
  SortDirections,
  ThemeColorsEnum,
} from "../../constants/enums";
import {
  AuthContext,
  LoadingContext,
  ModalContext,
} from "../../context/contextProvider";
import { Search, SearchFilterRequest } from "../../models/searchFilterRequest";

import DashboardIcon from "@mui/icons-material/Dashboard";
import HolidayVillageIcon from "@mui/icons-material/HolidayVillage";
import AddButton from "../../components/buttons/AddButton";
import Card from "../../components/cards/Card";
import EmptyDataBox from "../../components/dataTables/EmptyDataBox";
import NSDataTable from "../../components/dataTables/NSDataTable";
import NSDataTablePagination from "../../components/dataTables/NSDataTablePagination";
import { AppRoutes } from "../../constants/appRoutes";
import Constants from "../../constants/constants";
import useCurrentUserInfo from "../../hooks/useCurrentUserInfo";
import useCustomersDataTable from "../../hooks/useCustomersDataTable";
import Services from "../../services/services";
import Page from "../Page";
import CustomerDetails from "./CustomerDetails";

const title = "Customers";
const breadcrumbs = [
  {
    IconComponent: DashboardIcon,
    title: "Dashhboard",
    to: AppRoutes.dashboard,
  },
  {
    IconComponent: HolidayVillageIcon,
    title: "Customers",
  },
];

const CustomersPage = (props) => {
  const { setLoading } = useContext(LoadingContext);
  const { currentClient, cache, setCache } = useContext(AuthContext);
  const { showModal } = useContext(ModalContext);

  // data-tables - start
  const [currentPage, setCurrentPage] = useState(
    cache?.customers?.currentPage ?? 1
  );
  const [pageSize, setPageSize] = useState(
    cache?.customers?.pageSize ?? Constants.defaults.rowsPerPage
  );
  const [paginationData, setPaginationData] = useState({
    from: null,
    pages: null,
    records: null,
    to: null,
  });
  const [searchValue, setSearchValue] = useState(
    cache?.customers?.search ?? ""
  );
  const [sortOptions, setSortOptions] = useState({
    column: cache?.customers?.sortColumn ?? CustomerColumnsEnum.company,
    direction: cache?.customers?.sortDirection ?? SortDirections.ascending,
  });
  // data-tables - end

  const [customers, setCustomers] = useState([]);
  const dataTableInfo = useCustomersDataTable(handleDeleted);
  const userInfo = useCurrentUserInfo();

  useEffect(() => {
    setCache({
      ...cache,
      customers: {
        currentPage: currentPage,
        pageSize: pageSize,
        search: searchValue,
        sortColumn: sortOptions.column,
        sortDirection: sortOptions.direction,
      },
    });
    getCustomersAsync((currentPage - 1) * pageSize, pageSize, searchValue);

    return () => {};
  }, [currentClient, currentPage, pageSize, searchValue, sortOptions]); // eslint-disable-line react-hooks/exhaustive-deps

  const getCustomersAsync = async (
    start = 0,
    take = Constants.defaults.rowsPerPage,
    searchText = null
  ) => {
    // filter
    const filter = new SearchFilterRequest();
    filter.search = new Search(searchText);
    filter.start = start;
    filter.length = take;
    filter.order[0].column = sortOptions.column ?? CustomerColumnsEnum.company;
    filter.order[0].dir = sortOptions.direction ?? SortDirections.ascending;

    setLoading(true);
    const response = await Services.api.customers.getCustomers(filter);
    setLoading(false);

    if (!response.ok)
      return Services.utility.toast(
        "Could not fetch the customers",
        ThemeColorsEnum.error
      );

    setCustomers(response.data?.data);
    setPaginationData({
      from: (currentPage - 1) * pageSize + 1,
      pages: response.data?.pagesTotal,
      records: response.data?.recordsTotal,
      to:
        currentPage * pageSize > response.data?.recordsTotal
          ? response.data?.recordsTotal
          : currentPage * pageSize,
    });
  };

  function handleDeleted(customer) {
    setCustomers(customers.filter((x) => x.id !== customer.id));
  }

  const handlePageChange = async (page) => {
    setCurrentPage(page);
  };

  const handleRowClick = (event, row) => {
    event.stopPropagation();
    // opend the details modal
    showModal("Customer Details", <CustomerDetails customer={row} />, [
      {
        color: ThemeColorsEnum.quaternary,
        title: "Close",
      },
    ]);
  };

  const handleRowsPerPageChange = (newPerPage, currentPage) => {
    setCurrentPage(1);
    setPageSize(newPerPage);
  };

  const handleSearch = (searchValue) => {
    setSearchValue(searchValue);
  };

  const handleSort = (column, direction) => {
    setCurrentPage(1);
    setSortOptions({ column: column, direction: direction });
  };

  return (
    <Page breadcrumbs={breadcrumbs} title={title}>
      <Row>
        <Col>
          <Card>
            <Card.Body>
              <div className="dataTables_wrapper dt-bootstrap5 no-footer">
                {/* **** BOOTSTRAP PAGINATION **** */}
                <NSDataTablePagination
                  from={paginationData?.from}
                  lastPage={paginationData?.pages}
                  page={currentPage}
                  pageSize={pageSize}
                  total={paginationData?.records}
                  to={paginationData?.to}
                  onPageChange={handlePageChange}
                  onPageSizeChange={(newPerPage) =>
                    handleRowsPerPageChange(newPerPage, currentPage)
                  }
                />
                {/* **** DATA TABLE **** */}
                <NSDataTable
                  buttons={
                    <AddButton
                      to={AppRoutes.customers_create}
                      visible={userInfo?.canCreateCustomers}
                    />
                  }
                  className="dataTable table-no-more"
                  // dense
                  columns={dataTableInfo.columns}
                  //   conditionalRowStyles={conditionalRowStyles}
                  data={customers}
                  emptyDataComponent={<EmptyDataBox />}
                  onRowClick={handleRowClick}
                  onSearch={handleSearch}
                  onSort={handleSort}
                  paginate
                  search
                  searchValue={searchValue}
                  sortBy={sortOptions.column}
                  sortDirection={sortOptions.direction}
                />
                {/* **** BOOTSTRAP PAGINATION **** */}
                <NSDataTablePagination
                  from={paginationData?.from}
                  lastPage={paginationData?.pages}
                  page={currentPage}
                  pageSize={pageSize}
                  showRecordsInfo
                  total={paginationData?.records}
                  to={paginationData?.to}
                  onPageChange={handlePageChange}
                  onPageSizeChange={(newPerPage) =>
                    handleRowsPerPageChange(newPerPage, currentPage)
                  }
                />
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Page>
  );
};

export default CustomersPage;
