import * as Yup from "yup";

import { Col, Row } from "react-bootstrap";
import {
  CustomerColumnsEnum,
  RolesEnum,
  SortDirections,
  ThemeColorsEnum,
  UnitOfMeasurementsEnum,
} from "../../constants/enums";
import { AuthContext, LoadingContext } from "../../context/contextProvider";
import { Search, SearchFilterRequest } from "../../models/searchFilterRequest";

import Enumerable from "linq";
import { debounce } from "lodash";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import Card from "../../components/cards/Card";
import AppForm from "../../components/forms/Form";
import FormCheckboxField from "../../components/forms/FormCheckboxField";
import FormCustomerLocationSelect from "../../components/forms/FormCustomerLocationSelect";
import FormCustomerSelect from "../../components/forms/FormCustomerSelect";
import FormDatePickerField from "../../components/forms/FormDatePickerField";
import FormSelectField from "../../components/forms/FormSelectField";
import FormTextField from "../../components/forms/FormTextField";
import FormWorkOrderProducts from "../../components/forms/FormWorkOrderProducts";
import SubmitButton from "../../components/forms/SubmitButton";
import { AppRoutes } from "../../constants/appRoutes";
import Schemas from "../../constants/schemas";
import useBranchInfo from "../../hooks/useBranchInfo";
import useCurrentUserInfo from "../../hooks/useCurrentUserInfo";
import Services from "../../services/services";
import Page from "../Page";

const days = [
  { name: "Sunday", value: 0 },
  { name: "Monday", value: 1 },
  { name: "Tuesday", value: 2 },
  { name: "Wednesday", value: 3 },
  { name: "Thursday", value: 4 },
  { name: "Friday", value: 5 },
  { name: "Saturday", value: 6 },
];

const ScheduledOrderFormPage = ({
  breadcrumbs,
  title,
  scheduledOrder = {},
  ...otherProps
}) => {
  const [customers, setCustomers] = useState([]);
  const [customerPredictions, setCustomerPredictions] = useState([]);
  const [customerLocations, setCustomerLocations] = useState([]);
  const [products, setProducts] = useState([]);
  const [drivers, setDrivers] = useState([]);
  const [enableReinitialize, setEnableReinitialize] = useState(true);

  const { currentClient } = useContext(AuthContext);
  const { busy, setLoading } = useContext(LoadingContext);

  const { isPropaneBranch, isPetroleumBranch} = useBranchInfo();
  const userInfo = useCurrentUserInfo();
  const navigate = useNavigate();

  const initialValues = {
    id: scheduledOrder?.id ?? 0,
    assignedToUserId: scheduledOrder?.assignedToUserId ?? "",
    createOn: scheduledOrder?.scheduleJSON?.createOn ?? "",
    customerDisplayName: scheduledOrder?.customerDisplayName ?? "",
    customerId: scheduledOrder?.customerId ?? "",
    customerLocationId: scheduledOrder?.customerLocationId ?? "",
    dueDate: scheduledOrder?.dueDate ?? new Date(),
    dueOn: scheduledOrder?.scheduleJSON?.dueOn ?? "",
    instructions: scheduledOrder?.customerPermanentInstructions ?? "",
    isDriverLocked: scheduledOrder?.isDriverLocked ?? false,
    locationName: scheduledOrder?.locationName ?? "",
    notes: scheduledOrder?.notes ?? "",
    productList:
      scheduledOrder?.productList ??
      Enumerable.from(products)
        .select((x) => ({
          id: 0,
          productId: x.id,
          quantity: "",
          unitOfMeasurement: UnitOfMeasurementsEnum.liters,
        }))
        .orderBy((x) => x.order)
        .thenBy((x) => x.shortName)
        .take(4)
        .toArray(),
    // startDate: "2021-10-11T00:00:00",
    startDate: scheduledOrder?.scheduleJSON?.startDate ?? "",
    weeksInterval: scheduledOrder?.scheduleJSON?.weeksInterval ?? "",
  };

  const baseValidationSchema = useMemo(
    () =>
      Yup.object().shape({
        ...(isPropaneBranch ? Schemas.propaneOrderShape
          : isPetroleumBranch ? Schemas.petroleumOrderShape
          : Schemas.petroleumOrderShape),
        dueDate: Yup.date().optional(),
        dueOn: Yup.number().required().label("Due on"),
        createOn: Yup.number().required().label("Create on"),
        startDate: Yup.date().required().label("Start Date"),
        weeksInterval: Yup.number().required().label("Interval"),
      }),
      [isPropaneBranch, isPetroleumBranch]
  );

  const [validationSchema, setValidationSchema] =
    useState(baseValidationSchema);

  useEffect(() => {
    initAsync();
  }, [currentClient]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (scheduledOrder?.customerId) {
      setCustomers([
        {
          id: scheduledOrder.customerId,
          company: scheduledOrder.customerDisplayName,
        },
      ]);
      getCustomerLocationsAsync(scheduledOrder.customerId);
    }
  }, [scheduledOrder]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (customerLocations?.length > 0) {
      setValidationSchema(
        baseValidationSchema.concat(
          Yup.object({
            customerLocationId: Yup.number()
              .required()
              .label("Customer location")
              .typeError("Customer location is a required field"),
          })
        )
      );
    } else setValidationSchema(baseValidationSchema);
  }, [customerLocations, baseValidationSchema]);

  const getCustomerLocationsAsync = async (customerId) => {
    const response = await Services.api.customers.getLocations(customerId);

    if (!response.ok)
      return Services.utility.toast(
        "Could not download the locations",
        ThemeColorsEnum.error
      );

    setCustomerLocations(response.data);

    return response.data;
  };

  const getProductsAsync = async () => {
    const response = await Services.api.products.all();

    if (!response.ok)
      return Services.utility.toast(
        "Could not download the products",
        ThemeColorsEnum.error
      );

    setProducts(response.data);
  };

  const getUsersAsync = async () => {
    const response = await Services.api.users.getByRole(RolesEnum.driver);

    if (!response.ok)
      return Services.utility.toast(
        "Could not download the drivers",
        ThemeColorsEnum.error
      );

    const finalData = Services.utility.getDataForUserPicker(response.data);
    setDrivers(finalData);
  };

  const handleCustomerInputChange = async (text) => {
    if (!text) return setCustomerPredictions(customers);
    text = text.toLowerCase();

    // filter
    const filter = new SearchFilterRequest();
    filter.search = new Search(text);
    filter.start = 0;
    filter.length = 20;
    filter.order[0].column = CustomerColumnsEnum.company;
    filter.order[0].dir = SortDirections.ascending;

    // setLoading(true);
    const response = await Services.api.customers.getCustomers(filter);
    // setLoading(false);

    if (!response.ok)
      return Services.utility.toast(
        "Could not get the customers",
        ThemeColorsEnum.error
      );

    setCustomerPredictions(response.data?.data);
    // const matches = Services.utility.getCustomerPredictions(customers, text);
    // setCustomerPredictions(matches);
  };

  const handleCustomerInputChangeWithDebounce = useMemo(
    () =>
      debounce((text) => {
        handleCustomerInputChange(text?.trim());
      }, 500),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const handleSelectCustomer = async (customer, { setFieldValue, values }) => {
    if (!customer) {
      setCustomerLocations([]);
      setFieldValue("customerLocationId", null);
      return;
    }

    // autofill permanent instructions
    setFieldValue("instructions", customer?.permanentInstructions ?? "");
    setFieldValue("customerDisplayName", customer?.displayName ?? "");

    const locations = await getCustomerLocationsAsync(customer.id);
    if (locations?.length === 1)
      setFieldValue("customerLocationId", locations[0].id);

    if (isPropaneBranch) {
      // need to autofill the tank size
      const productsWithNewTankSize = values["productList"]?.map((product) => ({
        ...product,
        tankSize: customer?.tankSize,
      }));
      setFieldValue("productList", productsWithNewTankSize);
    }
  };

  const handleSubmit = async (values) => {
    const payload = {
      ...scheduledOrder,
      ...values,
      scheduleJSON: {
        createOn: values.createOn,
        dueOn: values.dueOn,
        startDate: values.startDate,
        weeksInterval: values.weeksInterval,
      },
    };
    setLoading(true);
    const response = await Services.api.workOrders.save(payload);
    setLoading(false);

    if (!response.ok)
      return Services.utility.toast(
        "Could not save the schedule",
        ThemeColorsEnum.error,
        response
      );

    Services.utility.toast("Saved", ThemeColorsEnum.success);

    navigate(AppRoutes.scheduledOrders);
  };

  const initAsync = async () => {
    setLoading(true);
    setCustomerPredictions([]);
    setCustomerLocations([]);
    await getProductsAsync();
    await getUsersAsync();
    setEnableReinitialize(false); // otherwise every time you select a customer it resets the form
    setLoading(false);
  };

  return (
    <Page breadcrumbs={breadcrumbs} title={title}>
      <Card className="mb-5">
        <Card.Body>
          <AppForm
            enableReinitialize={enableReinitialize}
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
          >
            <Row>
              <Col lg={4}>
                <FormCustomerSelect
                  allItems={customers}
                  autocomplete
                  hidePlusButton
                  itemLabelProperty="company"
                  items={customerPredictions}
                  itemValueProperty="id"
                  label="Customer"
                  name="customerId"
                  onChange={handleSelectCustomer}
                  onInputChange={handleCustomerInputChangeWithDebounce}
                  placeholder="Search by anything..."
                  required
                  searchable
                />
              </Col>
              <Col lg={4}>
                <FormCustomerLocationSelect
                  hidePlusButton
                  itemLabelProperty="name"
                  items={customerLocations}
                  itemValueProperty="id"
                  label="Customer Location"
                  name="customerLocationId"
                  required={customerLocations?.length > 0}
                  searchable
                />
              </Col>
              <Col lg={4}>
                <FormSelectField
                  disabled={
                    !userInfo?.isAdmin && scheduledOrder?.isDriverLocked
                  }
                  itemLabelProperty="label"
                  items={drivers}
                  itemValueProperty="value"
                  label="Driver"
                  name="assignedToUserId"
                  searchable
                />
                <FormCheckboxField
                  description="Only Managers can change the driver"
                  name="isDriverLocked"
                />
              </Col>
            </Row>

            <Row>
              <Col lg={6}>
                <FormWorkOrderProducts
                  availableProducts={products}
                  errorFieldName="products"
                  label="Products"
                  name="productList"
                  required
                />
              </Col>
            </Row>

            <Row>
              <Col lg={4}>
                <FormTextField
                  autoComplete="on"
                  label="Location Notes"
                  name="locationName"
                />
              </Col>
            </Row>

            <Row>
              <Col lg={6}>
                <FormTextField
                  as="textarea"
                  className="custom-text-area"
                  label="Hazard Assessment/Permanent Instructions"
                  name="instructions"
                />
              </Col>
              <Col lg={6}>
                <FormTextField
                  as="textarea"
                  className="custom-text-area"
                  label="One-time Notes"
                  name="notes"
                />
              </Col>
            </Row>

            <Row>
              <Col lg={3}>
                <FormSelectField
                  disabled={scheduledOrder?.id > 0}
                  info={
                    <>
                      Specify on which day the order is due.
                      <br />
                      To change this value you will have to delete and recreate
                      the schedule.
                    </>
                  }
                  itemLabelProperty="name"
                  items={days}
                  itemValueProperty="value"
                  label="Due on"
                  name="dueOn"
                  required
                  searchable
                />
              </Col>
              <Col lg={3}>
                <FormSelectField
                  disabled={scheduledOrder?.id > 0}
                  info={
                    <>
                      Day (prior to the due day) that the order should be
                      created.
                      <br />
                      To change this value you will have to delete and recreate
                      the schedule.
                    </>
                  }
                  itemLabelProperty="name"
                  items={days}
                  itemValueProperty="value"
                  label="Create on"
                  name="createOn"
                  required
                  searchable
                />
              </Col>
              <Col lg={3}>
                <FormTextField
                  info="How often will this schedule run?"
                  label="Interval"
                  name="weeksInterval"
                  placeholder="Weeks interval"
                  required
                  type="number"
                />
              </Col>
              <Col lg={3}>
                <FormDatePickerField
                  label="Start Date"
                  name="startDate"
                  placeholder="Select a date"
                  required
                />
              </Col>
            </Row>

            <div className="form-group mb-0 fixed-to-bottom">
              <Row>
                <Col lg={2} md={4} sm={6}>
                  <SubmitButton className="btn-block" loading={busy}>
                    Save
                  </SubmitButton>
                </Col>
              </Row>
            </div>
          </AppForm>
        </Card.Body>
      </Card>
    </Page>
  );
};

export default ScheduledOrderFormPage;
