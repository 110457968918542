import React from "react";
import { Link } from "react-router-dom";

const Card = ({
  children,
  className,
  color,
  featured,
  onClick,
  visible = true,
  ...otherProps
}) => {
  if (!visible) return null;

  className = className ? `card ${className}` : "card";

  if (onClick) className = `${className} cur-pointer`;
  if (featured) className = `${className} card-featured`;
  if (color) className = `${className} card-featured-${color}`;
  className = className.trim();

  return (
    <section className={className} onClick={onClick} {...otherProps}>
      {children}
    </section>
  );
};

const Title = ({ children }) => <h2 className="card-title">{children}</h2>;

const Header = ({ children, collapsible }) => (
  <header className="card-header">
    {!!collapsible && (
      <div className="card-actions">
        <Link
          to="#"
          className="card-action card-action-toggle"
          data-card-toggle=""
        ></Link>
      </div>
    )}
    {children}
  </header>
);
Header.Title = Title;
Card.Header = Header;

const Body = ({ children, ...otherProps }) => {
  const cardBodyClasses = `card-body ${otherProps?.className}`.trim();

  return <div className={cardBodyClasses}>{children}</div>;
};
Card.Body = Body;

const Footer = ({ children }) => <div className="card-footer">{children}</div>;
Card.Footer = Footer;

export default Card;
