import { useField, useFormikContext } from "formik";

import Enumerable from "linq";
import React from "react";
import { InputGroup } from "react-bootstrap";
import { ElementSize } from "../../constants/enums";
import AppErrorMessage from "./ErrorMessage";
import FieldInfo from "./FieldInfo";
import FormFieldContainer from "./FormFieldContainer";
import AppFormLabel from "./FormLabel";
import AppSelect from "./Select";

const FormSelectField = ({
  allItems,
  autocomplete,
  clearable,
  disabled = false,
  icon,
  info,
  initialText,
  itemLabelProperty,
  items = [],
  itemValueProperty,
  label,
  LabelComponent,
  multi,
  name,
  onBlur = () => {},
  onChange = () => {},
  onInputChange = () => {},
  placeholder,
  required,
  searchable = false,
  size = ElementSize.md,
  visible = true,
  ...otherProps
}) => {
  const formik = useFormikContext();
  const [field] = useField({ name: name, value: otherProps.value });

  const { errors, handleBlur, handleChange, setFieldValue, touched } =
    formik;

  if (!visible) return null;

  const handleInputChange = (text) => {
    if (text?.length === 0) return;
    onInputChange(text);
  };

  const handleSelect = (item) => {

    if (multi)
      setFieldValue(
        field.name,
        Enumerable.from(item)
          .select((x) => x[itemValueProperty])
          .toArray()
      );
    else setFieldValue(field.name, item?.[itemValueProperty] ?? null);

    handleBlur(field.name);
    handleChange(field.name);
    onChange(item, formik);
  };

  return (
    <FormFieldContainer>
      <AppFormLabel label={label} required={required} visible={!!label} />
      {LabelComponent}
      <InputGroup>
        <AppSelect
          allItems={allItems}
          autocomplete={autocomplete}
          clearable={clearable}
          disabled={disabled}
          initialText={initialText}
          itemLabelProperty={itemLabelProperty}
          items={items}
          itemValueProperty={itemValueProperty}
          multi={multi}
          name={field.name}
          onBlur={handleBlur(field.name)}
          onChange={handleSelect}
          onInputChange={handleInputChange}
          placeholder={placeholder}
          searchable={searchable}
          value={field.value}
          // value={selectedItem}
          // {...field}
          {...otherProps}
        />
        {icon && (
          <span className="input-group-text">
            <i className={icon}></i>
          </span>
        )}
      </InputGroup>
      <div>
        <AppErrorMessage
          error={errors?.[field.name]}
          visible={!!touched?.[field.name] && !!errors?.[field.name]}
        />
      </div>
      <FieldInfo visible={!!info}>{info}</FieldInfo>

      {/* <div>Value: {JSON.stringify(values?.[name])}</div> */}
      {/* <div>Touched: {JSON.stringify(touched?.[name])}</div> */}
      {/* <div>Error: {JSON.stringify(errors?.[name])}</div> */}
    </FormFieldContainer>
  );
};

export default FormSelectField;
