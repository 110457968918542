import { Form, InputGroup } from "react-bootstrap";

import AppErrorMessage from "./ErrorMessage";
import AppFormLabel from "./FormLabel";
import { ElementSize } from "../../constants/enums";
import FieldInfo from "./FieldInfo";
import FormFieldContainer from "./FormFieldContainer";
import React from "react";
import { useField } from "formik";
import { useFormikContext } from "formik";

const FormTextField = ({
  className,
  disabled = false,
  hasFloatingLabel,
  icon,
  info,
  label = null,
  LabelComponent,
  onChange = () => {},
  onIconClick = () => {},
  placeholder,
  required,
  size = ElementSize.md,
  visible = true,
  ...otherProps
}) => {
  const [field] = useField(otherProps);
  field.onChange = handleTextChange;

  const formik = useFormikContext();
  const { errors, handleBlur, setFieldValue, touched, values } = formik;

  if (!visible) return null;

  const classes = `form-control-${size} ${className ?? ""}`.trim();

  function handleTextChange(event) {
    const text = event.target.value;
    setFieldValue(field.name, text);
    onChange(text, formik);
  }

  return (
    <FormFieldContainer>
      <AppFormLabel label={label} required={required} visible={!!label} />
      {LabelComponent}
      <InputGroup>
        <Form.Control
          className={classes}
          disabled={disabled}
          name={field.name}
          onBlur={handleBlur}
          onChange={handleTextChange}
          placeholder={hasFloatingLabel ? placeholder ?? label : placeholder}
          // {...field}
          value={values[field.name]}
          {...otherProps}
        />
        {icon && (
          <span
            className={`input-group-text ${
              onIconClick === null ? "" : "cur-pointer"
            }`}
            onClick={onIconClick}
          >
            <i className={icon}></i>
          </span>
        )}
      </InputGroup>
      <div>
        <AppErrorMessage
          error={errors[field.name]}
          visible={touched[field.name] && errors[field.name]}
        />
      </div>
      <FieldInfo visible={!!info}>{info}</FieldInfo>

      {/* {JSON.stringify(values, null, 4)} */}
    </FormFieldContainer>
  );
};

export default FormTextField;
